import { MerchantUser, MerchantUserRole, User, UserRole } from '@/types';
import {
  Button,
  InputField,
  Skeleton,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { StyledTable, StyledTableRow } from './TeamTable.styles';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { StatusField } from '../StatusField/StatusField';
import {
  validateRoles,
  validateRolesByMerchantId,
  getRoleLabel,
  filterOutSuperAdminRole,
} from '@/utils/userUtils';
import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type SkeletonTableItemsPropsType = {
  show: boolean;
  title: string;
  numberOfSkeletons?: number;
};

export const SkeletonTableItems = ({
  show = false,
  title,
  numberOfSkeletons = 3,
}: SkeletonTableItemsPropsType) => {
  if (!show) {
    return null;
  }
  return (
    <>
      {[...Array(numberOfSkeletons)].map((_, index) => (
        <StyledTableRow key={`${title}-${index}`}>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export type TableItemsPropsType = {
  show: boolean;
  teamMembers: MerchantUser[] | undefined;
  user?: User | null;
  onRemoveClick: (merchantUser: MerchantUser) => void;
  onResendInviteClick: UseMutateFunction<boolean, AxiosError, number>;
};

export const TableItems = ({
  show = false,
  teamMembers,
  user,
  onRemoveClick,
  onResendInviteClick,
}: TableItemsPropsType) => {
  // Filter out users with the INSPLB_ADMIN role
  const filteredTeamMembers = teamMembers?.filter(({ user }) =>
    user?.roles?.some(role => role.role !== UserRole.INSPLB_ADMIN)
  );

  const isAuthorizedToRemoveUsers = validateRolesByMerchantId(
    user,
    [UserRole.OWNER, UserRole.SP_INTERNAL, UserRole.INSPLB_ADMIN],
    user?.primaryMerchantId
  );

  if (!show) {
    return null;
  }

  if (!teamMembers || (teamMembers && teamMembers.length === 0)) {
    return (
      <StyledTableRow>
        <TableCell colSpan={4}>
          <Typography variant="body2" align="center">
            No Team Members
          </Typography>
        </TableCell>
      </StyledTableRow>
    );
  }

  return (
    <>
      {filteredTeamMembers?.map(teamMember => {
        const displayRoles = filterOutSuperAdminRole(
          teamMember.user?.roles || []
        );

        return (
          <StyledTableRow key={teamMember.id}>
            <TableCell width="30%">
              <Typography variant="subtitle2">
                {teamMember.user?.firstName} {teamMember.user?.lastName}
              </Typography>
              <Typography variant="body3">{teamMember.user?.email}</Typography>
            </TableCell>
            <TableCell align="center" width="30%">
              {displayRoles && displayRoles.length > 0 && (
                <InputField
                  label="Roles"
                  select={displayRoles.length > 1} // Show dropdown if more than one role
                  inputProps={{ readOnly: displayRoles.length === 1 }} // No edit allowed
                  value={getRoleLabel(displayRoles[0].role)}
                  dropdownItems={displayRoles.map(
                    (merchantUserRole: MerchantUserRole) => ({
                      label: getRoleLabel(merchantUserRole.role),
                      value: merchantUserRole.role,
                    })
                  )}
                />
              )}
            </TableCell>
            <TableCell align="right" width="30%">
              <StatusField
                teamMember={teamMember}
                onResendInviteClick={onResendInviteClick}
              />
            </TableCell>
            <TableCell align="right">
              {isAuthorizedToRemoveUsers &&
                !validateRoles(teamMember.user, [
                  UserRole.OWNER,
                  UserRole.SP_INTERNAL,
                ]) && (
                  <Button
                    sx={{ alignSelf: 'flex-end' }}
                    size="small"
                    variant="outlined"
                    onClick={() => onRemoveClick(teamMember)}
                  >
                    Remove
                  </Button>
                )}
            </TableCell>
          </StyledTableRow>
        );
      })}
    </>
  );
};

export type TeamTablePropsType = {
  isLoading?: boolean;
  teamMembers: MerchantUser[] | undefined;
  user?: User | null;
  onResendInviteClick: UseMutateFunction<boolean, AxiosError, number>;
  onRemoveClick: (memberUser: MerchantUser) => void;
};

export const TeamTable = ({
  teamMembers,
  isLoading = false,
  user,
  onResendInviteClick,
  onRemoveClick,
}: TeamTablePropsType) => {
  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body3">Team member</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3">Role</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body3" color="primary.medium">
                Status
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <SkeletonTableItems show={isLoading} title="123" />
          <TableItems
            show={!isLoading}
            user={user}
            teamMembers={teamMembers}
            onResendInviteClick={onResendInviteClick}
            onRemoveClick={onRemoveClick}
          />
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};
