import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { DropdownType, Experience, ProductTypes } from '@/types';
import { LabelField } from '@/components';
import { useCategoriesChoices, useSubCategoriesChoices } from '@/hooks';
import { MenuProps, getStyles } from './BasicInfoSectionEdit.styles';
import { BasicInfoSectionEditInput } from '@/classes/BasicInfoSectionEditInput';
import { useEffect, useState } from 'react';

const resolver = classValidatorResolver(BasicInfoSectionEditInput);

export type BasicInfoSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: BasicInfoSectionEditInput) => void;
};

export const BasicInfoSectionEdit = ({
  experience,
  onFormSubmit,
}: BasicInfoSectionEditPropsType) => {
  const theme = useTheme();
  const [filteredSubcategories, setFilteredSubCategories] = useState<
    DropdownType[]
  >([]);

  const isProductTypeSpace = experience.type === ProductTypes.SPACE;
  const { data: categories } = useCategoriesChoices(
    isProductTypeSpace ? experience.type : undefined
  );

  const categoryIds = categories?.map(category => category.id);
  const { data: subcategories } = useSubCategoriesChoices(categoryIds);

  const categoriesArr =
    typeof experience.category?.type === 'string'
      ? [experience.category?.type]
      : experience.category?.type;

  const subCategoriesArr =
    typeof experience.category?.info === 'string'
      ? [experience.category?.info]
      : experience.category?.info;

  const {
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<BasicInfoSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      category: {
        info: subCategoriesArr || [],
        type: categoriesArr || [],
      },
    },
  });
  const selectedCategories = watch('category.type');
  const selectedSubCategories = watch('category.info');

  const removeUnselectedSubCategories = () => {
    const categoriesList = selectedCategories?.map(selectedCategory => {
      return categories?.find(category => category.value === selectedCategory);
    });
    const subcategoriesList = subcategories?.filter(subcategory => {
      const category = categoriesList?.find(
        category => category?.id === subcategory.categoryId
      );
      return !!category;
    });
    const newSubcategories = selectedSubCategories?.filter(
      selectedSubcategory => {
        return subcategoriesList?.find(
          subcategory => subcategory.value === selectedSubcategory
        );
      }
    );
    setValue('category.info', newSubcategories);
  };

  // If the product type is Space, we need to manually set the category type to 'spaces'
  // Hide category field, and only show Space's subcategories
  useEffect(() => {
    if (isProductTypeSpace) {
      setValue('category.type', ['spaces']);
    }
  }, [isProductTypeSpace]);

  useEffect(() => {
    if (selectedCategories && subcategories) {
      const categoriesList = selectedCategories?.map(selectedCategory => {
        return categories?.find(
          category => category.value === selectedCategory
        );
      });
      const filteredSubcategories = subcategories?.filter(subcategory => {
        const category = categoriesList?.find(
          category => category?.id === subcategory.categoryId
        );
        return !!category;
      });
      removeUnselectedSubCategories();
      setFilteredSubCategories(filteredSubcategories || []);
    }
  }, [selectedCategories, subcategories]);

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Basic Info</Typography>
      <form id="basicInfoForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4} width="100%">
          {!isProductTypeSpace && (
            <LabelField
              label="What is the category that best describes your experience?"
              value={
                <Box sx={{ display: 'inline-flex', mt: 2 }}>
                  <Controller
                    name="category.type"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        error={
                          !!(errors.category?.type as { message: string })
                            ?.message
                        }
                        fullWidth
                      >
                        <InputLabel id={`${field.name}-label`}>
                          Categories
                        </InputLabel>
                        <Select
                          {...field}
                          data-testid="categoryFieldBasicInfo"
                          labelId={`${field.name}-label`}
                          multiple
                          input={
                            <OutlinedInput
                              id={`select-multiple-${field.name}`}
                              label="Categories"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map(selectedCategory => (
                                <Chip
                                  key={selectedCategory}
                                  label={
                                    categories?.find(
                                      category =>
                                        category.value === selectedCategory
                                    )?.label || ''
                                  }
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {categories?.map(category => (
                            <MenuItem
                              key={category.id}
                              value={category.value}
                              style={getStyles(
                                category.value,
                                field.value as string[],
                                theme
                              )}
                            >
                              {category.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.category?.type}>
                          {/** hack because type is also internal value for hookform */}
                          {(errors.category?.type as { message: string })
                            ?.message || ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>
              }
            />
          )}

          {selectedCategories.length > 0 && (
            <LabelField
              label={
                isProductTypeSpace
                  ? 'What category best describes your space?'
                  : 'What is the subcategory that best describes your experience?'
              }
              value={
                <Box sx={{ display: 'inline-flex', mt: 2 }}>
                  <Controller
                    name="category.info"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormControl
                          error={!!errors.category?.info?.message}
                          fullWidth
                        >
                          <InputLabel id={`${field.name}-label`}>
                            {isProductTypeSpace
                              ? 'Select space type'
                              : 'Subcategories'}
                          </InputLabel>
                          <Select
                            {...field}
                            data-testid="subcategoryFieldBasicInfo"
                            labelId={`${field.name}-label`}
                            multiple
                            input={
                              <OutlinedInput
                                id={`select-multiple-${field.name}`}
                                label={
                                  isProductTypeSpace
                                    ? 'Select space type'
                                    : 'Subcategories'
                                }
                              />
                            }
                            renderValue={selected => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected.map(selectedSubCategory => (
                                  <Chip
                                    key={selectedSubCategory}
                                    label={
                                      filteredSubcategories?.find(
                                        subCategory =>
                                          subCategory.value ===
                                          selectedSubCategory
                                      )?.label || ''
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {filteredSubcategories?.map(subcategory => (
                              <MenuItem
                                key={subcategory.id}
                                value={subcategory.value}
                                style={getStyles(
                                  subcategory.value,
                                  field.value as string[],
                                  theme
                                )}
                              >
                                {subcategory.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={!!errors.category?.info}>
                            {/** hack because type is also internal value for hookform */}
                            {!!errors.category?.info?.message
                              ? errors.category?.info?.message
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      </>
                    )}
                  />
                </Box>
              }
            />
          )}
        </Stack>
      </form>
    </Stack>
  );
};
