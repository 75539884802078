import { NumericCircle } from '@/components/NumericCircle';
import { useFeatureFlags } from '@/hooks';
import { Booking, PaymentStatus, StatementBooking } from '@/types';
import { formatPricing, isTypeBooking, pluralize } from '@/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
} from '@mui/material';
import {
  Box,
  Grid,
  Typography,
  Label,
} from '@silverstein-properties/inspirelabs-ui';
import { useState } from 'react';
import { StyledList } from './GuestList.styles';

export type GuestListProps = {
  bookings?: StatementBooking[] | Booking[];
  isLoading: boolean;
  title: string;
  handleClick: (booking: Booking) => void;
  handleRefund?: (booking: Booking) => void;
  enableMessaging?: boolean;
};

export const GuestList = ({
  bookings,
  isLoading,
  title,
  handleClick,
  enableMessaging = true,
  handleRefund,
}: GuestListProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAnchorIndex, setCurrentAnchorIndex] = useState(0);
  const isOpen = Boolean(anchorEl);

  const { isMessageGuestsEnabled, isMerchantRefundingEnabled } =
    useFeatureFlags();

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setCurrentAnchorIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefundClick = (booking: Booking) => {
    if (handleRefund && isTypeBooking(booking)) {
      handleRefund(booking);
    }
    handleClose();
  };

  const handleMessageClick = (booking: Booking) => {
    if (isTypeBooking(booking)) {
      handleClick(booking);
    }
    handleClose();
  };

  const totalGuests =
    bookings?.length && isTypeBooking(bookings[0])
      ? (bookings as Booking[]).reduce(
          (accumulator, booking) =>
            accumulator + booking.nbOfAdditionalGuests + 1,
          0
        )
      : bookings?.length;

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container item alignItems="center" xs={12} gap={1}>
        <Typography variant="h5">{title}</Typography>
        <NumericCircle isLoading={isLoading} size="sm" value={totalGuests} />
      </Grid>
      <StyledList>
        {isLoading
          ? [...Array(3)].map((_item, i) => (
              <ListItem key={`${title}_${i}`}>
                <ListItemText>
                  <Skeleton />
                </ListItemText>
              </ListItem>
            ))
          : bookings?.map((booking, i) => {
              const participantFirstName = isTypeBooking(booking)
                ? booking.consumer?.firstName
                : booking.participantFirstName;
              const participantLastName = isTypeBooking(booking)
                ? booking.consumer?.lastName
                : booking.participantLastName;
              const bookingPrice = isTypeBooking(booking)
                ? booking.pricing?.total || booking.listingSnapshot.totalAmount
                : booking.totalEarned;
              return (
                <ListItem
                  key={isTypeBooking(booking) ? booking.id : booking.bookingId}
                >
                  <ListItemText>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs>
                        <Typography>
                          {participantFirstName} {participantLastName}
                        </Typography>
                      </Grid>
                      {booking.paymentStatus === PaymentStatus.REFUNDED && (
                        <Grid item xs>
                          <Label
                            size="tiny"
                            color="white"
                            backgroundColor="error.main"
                            ml={0.5}
                          >
                            Refunded
                          </Label>
                        </Grid>
                      )}
                      {!isTypeBooking(booking) ? (
                        <Grid container item xs="auto" flexDirection="column">
                          {booking.nbOfGuests ? (
                            <Grid item>
                              <Typography>
                                {booking.nbOfGuests} guest
                                {booking.nbOfGuests > 1 ? 's' : ''}
                              </Typography>
                            </Grid>
                          ) : null}
                          <Grid item>
                            <Typography>
                              {formatPricing(bookingPrice, 2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container item xs="auto" flexDirection="column">
                          <Grid item>
                            <Typography>
                              {pluralize(
                                booking.nbOfAdditionalGuests + 1,
                                'guest'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              color={
                                title === 'Cancellations' ? 'error' : 'primary'
                              }
                              variant="subtitle2"
                            >
                              {bookingPrice
                                ? formatPricing(bookingPrice, 2)
                                : 'Free'}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </ListItemText>
                  {isMessageGuestsEnabled && enableMessaging ? (
                    <>
                      <IconButton
                        disableRipple
                        aria-label="more"
                        onClick={event => handleMenuClick(event, i)}
                        data-testid={`more-button-${
                          isTypeBooking(booking)
                            ? booking.id
                            : booking.bookingId
                        }`}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {anchorEl && (
                        <Menu
                          anchorEl={anchorEl}
                          open={isOpen && i === currentAnchorIndex}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          sx={{ mt: 1 }}
                        >
                          <MenuItem
                            disableRipple
                            onClick={() =>
                              enableMessaging && isTypeBooking(booking)
                                ? handleMessageClick(booking)
                                : null
                            }
                          >
                            Email Guest
                          </MenuItem>
                          {isMerchantRefundingEnabled &&
                          booking.paymentStatus !== PaymentStatus.REFUNDED ? (
                            <MenuItem
                              disableRipple
                              onClick={() =>
                                isTypeBooking(booking)
                                  ? handleRefundClick(booking)
                                  : null
                              }
                            >
                              Refund
                            </MenuItem>
                          ) : null}
                        </Menu>
                      )}
                    </>
                  ) : null}
                </ListItem>
              );
            })}
      </StyledList>
    </Box>
  );
};
