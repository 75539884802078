export interface UpdatePriceDto {
  priceId?: string;
  membershipPlanProductId?: string;
  amount: number;
  taxRateId?: number;
  priceType: PriceType;
  description?: string;
  membershipPlanId?: string;
}

export interface UpdateProductPricesDto {
  id: string;
  prices: UpdatePriceDto[];
}

export enum PriceType {
  PRICE_PER_PERSON = 'PRICE_PER_PERSON',
  FLAT_FEE = 'FLAT_FEE',
  VIA_QUOTE = 'VIA_QUOTE',
  MEMBERSHIP = 'MEMBERSHIP',
  PER_HOUR = 'PER_HOUR',
  PER_DAY = 'PER_DAY',
}

export enum PriceTypeLabel {
  PRICE_PER_PERSON = 'Price per person',
  FLAT_FEE = 'Flat fee',
  MEMBERSHIP = 'Membership',
}

export interface Price {
  id?: string;

  netAmount: number;
  feePercentage: number;
  feeAmount: number;
  totalAmount: number;

  priceType: PriceType;
  taxRateId?: number;
  description?: string;
  membershipPlanId?: string;

  createdUserId: string;
  createdAt: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  deletedUserId?: string;
  deletedAt?: Date;
}
