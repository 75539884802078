import {
  Stack,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
} from '@silverstein-properties/inspirelabs-ui';
import {
  Controller,
  ControllerRenderProps,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Amenity, AmenityPriceType, AmenityType, Experience } from '@/types';
import { LabelField } from '@/components';
import { AddOnFormItem, AmenitiesSectionEditInput } from '@/classes';
import { AmenitiesSkeleton } from './AmenitiesSkeleton';
import { useAmenitiesByType } from '@/hooks/queries/useAmenities';
import { AddOnItem } from './Components/AmenityAddOn';
import { ChangeEvent } from 'react';

const resolver = classValidatorResolver(AmenitiesSectionEditInput);

export type AmenitiesSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (
    data: Pick<Experience, 'amenities' | 'bookingRequestInfo'>
  ) => Promise<void>;
};

const DEFAULT_ADD_ON_ICON = 'GrowthIcon';

export const AmenitiesSectionEdit = ({
  experience,
  onFormSubmit,
}: AmenitiesSectionEditPropsType) => {
  const { data: amenities = [], isLoading: amenitiesLoading } =
    useAmenitiesByType(AmenityType.INCLUDED);
  const { data: addOns = [], isLoading: addOnsLoading } = useAmenitiesByType(
    AmenityType.ADD_ON
  );

  const defaultAddOns = (experience?.amenities || [])?.filter(
    amenity => amenity.type === AmenityType.ADD_ON
  );
  const defaultCustomAddOns = (experience?.amenities || [])?.filter(
    amenity => amenity.type === AmenityType.CUSTOM_ADD_ON
  );

  const formAmenities: AddOnFormItem[] = amenities.map(amenity => ({
    name: amenity.name,
    type: amenity.type,
    icon: amenity.icon,
  }));

  const formAddOns: AddOnFormItem[] = addOns.map(addOn => ({
    name: addOn.name,
    type: addOn.type,
    icon: addOn.icon,
    unit: addOn?.pricing?.priceType,
    price: addOn?.pricing?.totalAmount || 0,
  }));

  const defaultValues = {
    amenities: (experience?.amenities || [])?.filter(
      amenity => amenity.type === AmenityType.INCLUDED
    ),
    addOns: [],
    customAddOns: defaultCustomAddOns.map(addOn => ({
      id: addOn.id,
      name: addOn.name,
      type: addOn.type,
      unit: addOn?.pricing?.priceType,
      price: addOn?.pricing?.totalAmount || 0,
    })),
    initialDetailsPrompt: experience?.bookingRequestInfo?.info || '',
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<AmenitiesSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: defaultValues,
    values: {
      ...defaultValues,
      addOns: defaultAddOns.reduce<AddOnFormItem[]>((acc, addOn) => {
        // Find the index of the matching add-on in formAddOns
        const index = formAddOns.findIndex(
          formAddOn => formAddOn.name === addOn.name
        );

        // Place default add-on at the matching index
        // to match the order of pricing inputs in the form
        if (index !== -1) {
          acc[index] = {
            ...formAddOns[index],
            unit: addOn.pricing?.priceType,
            price: addOn.pricing?.totalAmount || 0,
          };
        }

        return acc;
      }, []),
    },
  });

  const { fields: customAddOns, append: addCustomAddOn } = useFieldArray({
    control,
    name: 'customAddOns',
  });

  const handleAddCustomAddOn = () =>
    addCustomAddOn({
      name: '',
      unit: undefined,
      price: 0,
      type: AmenityType.CUSTOM_ADD_ON,
    });

  const watchedAddOns = watch('addOns');
  const watchedCustomAddOns = watch('customAddOns');

  const handleFormSubmit = (formData: AmenitiesSectionEditInput) => {
    const mappedAddOns: Amenity[] = [
      ...formData.addOns,
      ...formData.customAddOns,
    ].map(addOn => ({
      id: addOn?.id,
      name: addOn.name,
      type: addOn.type,
      icon: addOn.icon ?? DEFAULT_ADD_ON_ICON,
      pricing: {
        priceType: addOn.unit as AmenityPriceType,
        netAmount: Number(addOn.price),
        totalAmount: Number(addOn.price),
      },
    }));

    const data = {
      amenities: [...formData.amenities, ...mappedAddOns],
      ...(formData.initialDetailsPrompt && {
        bookingRequestInfo: {
          info: formData.initialDetailsPrompt,
        },
      }),
    };
    onFormSubmit(data);
  };

  const onChangeAmenity = (
    field: ControllerRenderProps<AmenitiesSectionEditInput, 'amenities'>,
    amenity: AddOnFormItem,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.checked
      ? [...field.value, amenity]
      : field.value.filter((item: Amenity) => item.name !== amenity.name);
    field.onChange(newValue);
  };

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Amenities & add-ons</Typography>
      <form id="amenitiesForm" onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing={4}>
          {/* TODO: Add this back in when we're ready to launch and dependent component is ready */}
          {/* <LabelField
            spacing={2}
            label="Customers will contact you to request a quote. Please indicate what initial details you would like them to provide."
            value={
              <InputField
                {...register('initialDetailsPrompt')}
                inputProps={{
                  'data-testid': 'experienceLocation.description',
                }}
                multiline
                minRows={5}
                fullWidth
                label="Initial details"
                error={!!errors.initialDetailsPrompt}
                placeholder={
                  'Example: "In a few words, let us know what you need and how many attendees you are planning to have."'
                }
                helperText={errors.initialDetailsPrompt?.message}
              />
            }
          /> */}
          <LabelField
            label="Select the space amenities (included in the booking price)"
            value={
              amenitiesLoading ? (
                <AmenitiesSkeleton />
              ) : (
                <Box mt={3}>
                  {formAmenities.map(amenity => (
                    <Box key={amenity.name} width="100%">
                      <FormControlLabel
                        label={amenity.name}
                        control={
                          <Controller
                            control={control}
                            name="amenities"
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value.some(
                                  item => item.name === amenity.name
                                )}
                                onChange={e =>
                                  onChangeAmenity(field, amenity, e)
                                }
                              />
                            )}
                          />
                        }
                      />
                    </Box>
                  ))}
                </Box>
              )
            }
          />
          <LabelField
            label="Add-ons (not included in booking price)"
            value={
              addOnsLoading ? (
                <AmenitiesSkeleton />
              ) : (
                <Box mt={3}>
                  {formAddOns.map((addOn, index) => {
                    const watchedValue = watchedAddOns[index];
                    return (
                      <AddOnItem
                        key={addOn.id}
                        addOn={addOn}
                        control={control}
                        watchedValue={watchedValue}
                        index={index}
                        errors={errors}
                      />
                    );
                  })}
                </Box>
              )
            }
          />
          <LabelField
            label="Custom add-ons"
            value={
              <Box mt={3}>
                {customAddOns.map((customAddOn, index) => (
                  <AddOnItem
                    key={customAddOn.id}
                    addOn={customAddOn}
                    control={control}
                    index={index}
                    isCustom
                    watchedValue={watchedCustomAddOns[index]}
                    errors={errors}
                  />
                ))}
                <Button variant="text" onClick={handleAddCustomAddOn}>
                  + Add
                </Button>
              </Box>
            }
          />
        </Stack>
      </form>
    </Stack>
  );
};
