import { LabelField } from '@/components';
import { Experience, ProductTypes } from '@/types';
import { Stack } from '@silverstein-properties/inspirelabs-ui';

export type CapacitySectionPropsType = {
  experience: Experience;
};

export const CapacitySection = ({ experience }: CapacitySectionPropsType) => {
  const isProductTypeSpace = experience.type === ProductTypes.SPACE;

  return (
    <Stack spacing={4}>
      <LabelField
        label="Max no. of guests"
        value={
          experience?.capacity?.max?.toString() ||
          'Maximum capacity not specified'
        }
      />
      {isProductTypeSpace ? (
        <LabelField
          label="No. of seats"
          value={
            experience?.capacity?.seatCount?.toString() ||
            'Number of seats not specified'
          }
        />
      ) : (
        <LabelField
          label="Guests"
          value={
            !!experience.groupBookingsAllowed
              ? 'This product allows group bookings'
              : 'This product does not allow group bookings'
          }
        />
      )}
    </Stack>
  );
};
