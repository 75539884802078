import { Transform, Type } from 'class-transformer';
import {
  Max,
  Min,
  IsNumber,
  IsBoolean,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
} from 'class-validator';

export class CapacityInput {
  @Transform(({ value }) => parseInt(value))
  @IsNotEmpty({ message: 'Please enter a maximum capacity.' })
  @Max(999, { message: 'Maximum capacity cannot be greater than 999' })
  @Min(1, { message: 'Maximum capacity cannot be less than 1' })
  @IsNumber(undefined, { message: 'Maximum capacity must be a number' })
  max: string | number;

  @IsOptional()
  @Transform(({ value }) => (value === '' ? undefined : Number(value)))
  @IsNumber({}, { message: 'Seat count must be a number' })
  seatCount: string | number;
}

export class CapacitySectionEditInput {
  @ValidateNested()
  @Type(() => CapacityInput)
  capacity: CapacityInput;

  @IsBoolean()
  groupBookingsAllowed: boolean;
}
