import {
  Checkbox,
  FormControlLabel,
  InputField,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldValues,
  FormState,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { PricingFormInput } from '@/classes';
import {
  PricingWithTaxes,
  PricingWithTaxesSkeleton,
} from '../PricingWithTaxes';
import {
  Experience,
  MembershipPlan,
  PriceType,
  ProductTypes,
  PurchaseMethods,
} from '@/types';
import { FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  evaluateIsExperienceBookingRequest,
  evaluateIsPriceMembersExclusive,
} from '@/utils';
import { BookingRequestPrice } from '../PricingSectionEdit';
import { PRICE_UNIT_CHOICES } from '@/constants';

enum BookingRequestPriceLabel {
  PRICE_VIA_QUOTE = 'Price is agreed upon with customer via quote',
  PRICE_PER_GUEST = 'Price is per guest',
}

interface PricingFormProps<T extends FieldValues> {
  experience: Experience;
  control: Control<T>;
  formState: FormState<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  resetField: UseFormResetField<T>;
  fields: FieldArrayWithId[];
  platformFeeRaw?: number;
  isInsideDrawer?: boolean;
  isLoading: boolean;
  hideTitle?: boolean;
  hidePaymentMethods?: boolean;
  membershipPlans?: MembershipPlan[];
}

// TODO: refactor this component and PricingComponent once all other modules decommission the old model
export const PricingForm = ({
  control,
  experience,
  formState,
  setValue,
  watch,
  resetField,
  fields,
  platformFeeRaw,
  membershipPlans,
  isInsideDrawer = false,
  isLoading,
}: PricingFormProps<PricingFormInput>) => {
  const theme = useTheme();
  const isPartOfMembershipPlan = membershipPlans && membershipPlans?.length > 0;
  const isMemberExclusive = evaluateIsPriceMembersExclusive(
    watch('acceptedPurchaseMethods')
  );

  const priceType = watch(`prices.${0}.priceType`);
  const amount = watch(`prices.${0}.amount`);
  const [isPricePerGuest, setIsPricePerGuest] = useState(
    priceType !== '' ? priceType === PriceType.PRICE_PER_PERSON : !!amount
  );
  const isExperienceBookingRequest =
    evaluateIsExperienceBookingRequest(experience);

  // Reset the non member pricing (aka basePrice) for the product when pricing is membership exclusive
  useEffect(() => {
    if (isMemberExclusive) {
      resetField(`prices.${0}.amount`);
      resetField(`prices.${0}.taxRateId`);
      resetField(`prices.${0}.priceType`);
    }
  }, [isMemberExclusive]);

  const handleBookingRequest = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setIsPricePerGuest(value === BookingRequestPrice.PRICE_PER_GUEST);
    if (value !== BookingRequestPrice.PRICE_PER_GUEST) {
      setValue(`prices.${0}.amount`, '', { shouldDirty: true });
      setValue(`prices.${0}.taxRateId`, '', { shouldDirty: true });
    } else {
      resetField(`prices.${0}.amount`);
      resetField(`prices.${0}.taxRateId`);
    }
    setValue(
      `prices.${0}.priceType`,
      value === BookingRequestPrice.PRICE_VIA_QUOTE
        ? PriceType.VIA_QUOTE
        : PriceType.PRICE_PER_PERSON,

      {
        shouldDirty: true,
      }
    );
  };

  const handleAcceptedPurchaseMethodsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(
      'acceptedPurchaseMethods',
      event.target.checked
        ? [PurchaseMethods.SUBSCRIPTION]
        : [PurchaseMethods.ONE_TIME_MONEY_PURCHASE],
      {
        shouldDirty: true,
      }
    );
    setValue(`prices.${0}.isMemberExclusive`, event.target.checked, {
      shouldDirty: true,
    });
  };

  const pricingComponent = isLoading ? (
    <PricingWithTaxesSkeleton />
  ) : isPartOfMembershipPlan ? (
    <Stack
      width="100%"
      spacing={theme.spacers.xxl}
      marginBottom={theme.spacers.xl}
    >
      {/* Non-members and members pricing */}
      {fields.map((field, i) => {
        // Subtract 1 from index since the first field is not part of the plan pricing
        const plan = membershipPlans[i - 1];
        return (
          <PricingWithTaxes
            key={field.id}
            control={control}
            fieldIndex={i}
            formState={formState}
            watch={watch}
            platformFeeRaw={platformFeeRaw}
            // Title and disabled props varies whether field is for the base
            // price for the product or if its a membership plan product.
            // Here the first field will always be the base price for the product
            title={
              i === 0
                ? 'Non-members'
                : `${plan?.subscriptionName} - ${plan?.name}`
            }
            disabled={isMemberExclusive && i === 0}
          />
        );
      })}
    </Stack>
  ) : (
    /* Non-members pricing */
    <PricingWithTaxes
      control={control}
      fieldIndex={0}
      formState={formState}
      watch={watch}
      platformFeeRaw={platformFeeRaw}
      disabled={isMemberExclusive}
    />
  );

  const isTypeSpace = experience.type === ProductTypes.SPACE;

  const renderSubtitle = () => {
    if (isTypeSpace) {
      return 'What’s the space booking price?';
    }
    return isExperienceBookingRequest
      ? "What's the price?"
      : 'Set a price for your event';
  };

  return (
    <>
      <Typography
        variant={isInsideDrawer ? 'h5' : 'h4'}
        marginBottom={isInsideDrawer ? theme.spacers.xxl : theme.spacers.xxl2}
      >
        Pricing
      </Typography>
      <Typography variant="subtitle1" marginBottom={theme.spacers.m}>
        {renderSubtitle()}
      </Typography>
      {!isExperienceBookingRequest && (
        <Typography variant="body2" marginBottom={theme.spacers.m}>
          {isTypeSpace
            ? 'Please note that final price will be communicated to the customer via quote and final agreement.'
            : 'Explanation of fees & taxes on top of base prices'}
        </Typography>
      )}
      {isExperienceBookingRequest && (
        <RadioGroup
          onChange={handleBookingRequest}
          value={
            isPricePerGuest
              ? BookingRequestPrice.PRICE_PER_GUEST
              : BookingRequestPrice.PRICE_VIA_QUOTE
          }
        >
          <FormControlLabel
            value={BookingRequestPrice.PRICE_VIA_QUOTE}
            control={<Radio />}
            label={
              <Typography variant="body2">
                {BookingRequestPriceLabel.PRICE_VIA_QUOTE}
              </Typography>
            }
            sx={{ marginRight: 0 }}
          />
          <FormControlLabel
            value={BookingRequestPrice.PRICE_PER_GUEST}
            control={<Radio />}
            label={
              <Typography variant="body2">
                {BookingRequestPriceLabel.PRICE_PER_GUEST}
              </Typography>
            }
            sx={{ marginRight: 0 }}
          />
        </RadioGroup>
      )}
      {/* Show the pricing object if product of type booking request and pricing is per guest */}
      {/* Otherwise, always show the pricing component if product is of type instant */}
      {(isPricePerGuest || !isExperienceBookingRequest) && (
        <>
          {!(isExperienceBookingRequest || isTypeSpace) && (
            <Controller
              control={control}
              name="acceptedPurchaseMethods"
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      // NOTE: Need to do it this way because currently design only has a toggle checkbox but BE expect an array of possible values
                      <Checkbox
                        {...field}
                        checked={field.value?.includes(
                          PurchaseMethods.SUBSCRIPTION
                        )}
                        onChange={handleAcceptedPurchaseMethodsChange}
                      />
                    }
                    label="Member exclusive"
                  />
                </FormGroup>
              )}
            />
          )}
          {pricingComponent}
          {isTypeSpace && (
            <Controller
              control={control}
              defaultValue={PriceType.FLAT_FEE}
              name={`prices.${0}.priceType`}
              render={({ field }) => (
                <InputField
                  {...field}
                  label="Price unit"
                  select
                  dropdownItems={PRICE_UNIT_CHOICES}
                />
              )}
            />
          )}
        </>
      )}
    </>
  );
};
