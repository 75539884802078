import { useExperience, useLocations, useMerchant } from '@/hooks';
import {
  Button,
  Container,
  Grid,
  MessageBanner,
  Stack,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useLocation, useNavigate, useParams } from 'react-router';
import AlertDialog from '@/components/AlertDialog/AlertDialog';
import {
  TitleAndDescriptionSection,
  TitleAndDescriptionSectionEdit,
  AboutHostSection,
  AboutHostSectionEdit,
  CancelationSection,
  CancelationSectionEdit,
  DetailSection,
  FloatingMenu,
  LocationSection,
  LocationSectionEdit,
  PhotoSection,
  PhotoSectionEdit,
  PricingSection,
  PricingSectionEdit,
  RequirementsSection,
  RequirementsSectionEdit,
  CapacitySection,
  CapacitySectionEdit,
  RequestCommunicationSection,
  RequestCommunicationSectionEdit,
  BasicInfoSection,
  SubmitForApprovalSection,
  BasicInfoSectionEdit,
  AdvertUrlSection,
  SquareConnectionSection,
  SquareConnectionSectionEdit,
  AdvertUrlSectionEdit,
  BookingRequestSection,
  BookingScheduleSection,
  AmenitiesSectionEdit,
  AmenitiesSection,
  AudienceAccessSectionEdit,
  AudienceAccessSection,
} from './components';
import { useEffect, useRef, useState } from 'react';
import { DetailsDrawer } from '@/components';
import { ApiClient } from '@/api/apiClient';
import {
  PricingWithTaxesInputToUpdateProductPricingDto,
  checkValuesInObject,
  deepObjDiff,
  scrollWithOffset,
} from '@/utils';
import { MerchantType } from '@/types/Merchant';
import { getExperienceDetailMenus } from '@/utils/experienceUtils';
import {
  AboutHostSectionEditInput,
  AudienceAccessSectionEditInput,
  PricingFormInput,
} from '@/classes';
import useExperienceMutation from '@/hooks/mutations/useExperienceMutation';
import { LocationSectionEditInput } from '@/classes/LocationSectionEditInput';
import {
  BookingMethod,
  Experience,
  ExperienceStatus,
  Location,
  UpdatePriceDto,
} from '@/types';
import { flatten, flattenDeep, omit, values } from 'lodash';
import {
  EXPERIENCE_SECTIONS_MENUS,
  ALERT_DIALOGS,
  ExperiencesDetailId,
} from '@/constants';

export const ExperienceDetailsModule = () => {
  const theme = useTheme();
  const { experienceId = '' } = useParams();
  const navigate = useNavigate();
  const {
    data: experience,
    refetch,
    isInitialLoading,
  } = useExperience({ experienceId });
  const { data: location, refetch: refetchLocation } = useLocations({
    locationId: experience?.location?.id?.toString() || '',
  });

  const { mutateAsync: updateExperience } = useExperienceMutation();

  const menuRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editScreen, setEditScreen] = useState('');
  const apiClient = ApiClient();
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState(
    EXPERIENCE_SECTIONS_MENUS[0].id // Show experience details section by default
  );
  // If Exp is published, show experience management section
  useEffect(() => {
    if (experience?.status === ExperienceStatus.Published) {
      setSelectedSectionId(EXPERIENCE_SECTIONS_MENUS[1].id);
    }
  }, [experience?.status]);

  const browserLocation = useLocation();
  const lastHash = useRef('');

  const [pendingChanges, setPendingChanges] = useState<{
    changes?: Partial<Experience>;
    location?: Partial<Location>;
    prices?: UpdatePriceDto[];
  } | null>(null);

  const { data: merchant } = useMerchant();

  useEffect(() => {
    // Navigate to previous page instead of navigating back to previous section on page
    const navigateBackListener = () => {
      navigate(-1);
    };
    addEventListener('popstate', navigateBackListener);

    return () => {
      removeEventListener('popstate', navigateBackListener);
    };
  }, []);

  useEffect(() => {
    if (browserLocation.hash) {
      lastHash.current = browserLocation.hash.slice(1);
    }
    // Find the section to scroll to
    const initialSection = EXPERIENCE_SECTIONS_MENUS.find(x =>
      x.menu.some(y => y.id === lastHash.current)
    );
    const element = document.getElementById(lastHash.current);
    if (lastHash.current && initialSection) {
      setSelectedSectionId(initialSection.id);

      setTimeout(() => {
        if (element) {
          scrollWithOffset(element, theme);
        }
        // reset last hash
        lastHash.current = '';
      }, 100);
    }
  }, [isInitialLoading]);

  if (!experience) {
    return null;
  }

  const detailsMenu = getExperienceDetailMenus(
    merchant?.type || MerchantType.STANDARD,
    experience.bookingMethods
  );

  const isApproved =
    experience &&
    [
      ExperienceStatus.Published,
      ExperienceStatus.Approved,
      ExperienceStatus.Hidden,
    ].includes(experience.status);

  const isCompleted = checkValuesInObject(
    experience,
    flattenDeep(
      flatten(values(detailsMenu).map(section => section.menu)).map(
        item =>
          (experience.bookingMethods || [BookingMethod.INSTANT])?.map(
            bookingMethod => item.completeInfoCheck[bookingMethod] || []
          ) || []
      )
    ) as string[]
  );

  const onWindowClose = () => {
    setDrawerOpen(false);
  };

  const onEditClick = (screenName: string) => {
    setEditScreen(screenName);
    setDrawerOpen(true);
  };

  const onFormSubmit = async <T,>(data: T) => {
    switch (editScreen) {
      case 'audienceAccess':
        onFormSubmitAudienceAccess(data as AudienceAccessSectionEditInput);
        break;
      case 'pricing':
        onFormSubmitPricing(data as PricingFormInput);
        break;
      case 'location':
        onFormSubmitLocation(data as LocationSectionEditInput);
        break;
      case 'aboutTheHost':
        onFormSubmitHost(data as AboutHostSectionEditInput);
        break;
      default:
        onFormSubmitGeneric(data);
        break;
    }
  };

  const onFormSubmitGeneric = async <T,>(data: T) => {
    try {
      const { newChanges, newCompleteChanges } = deepObjDiff(experience, data);
      if (Object.keys(newChanges).length) {
        if (
          ['cancelation', 'requirements'].includes(editScreen) &&
          isApproved
        ) {
          setPendingChanges({
            changes: newCompleteChanges as Partial<Experience>,
          });
          setShowAlertDialog(true);
          return;
        }
        await updateExperience({
          changes: newCompleteChanges,
          productId: experienceId,
        });
        refetch();
      }
      setDrawerOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onFormSubmitAudienceAccess = async (
    data: AudienceAccessSectionEditInput
  ) => {
    try {
      const { newChanges } = deepObjDiff(experience, {
        buildingIds: data.buildingIds,
        buildingsTenants: data.buildingsTenants,
      });
      await updateExperience({
        changes: newChanges,
        productId: experienceId,
      });
      refetch();
      setDrawerOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onFormSubmitLocation = async ({
    shouldValidateAddress,
    ...rest
  }: LocationSectionEditInput) => {
    try {
      const remainingLocation = omit(rest, [
        'hasServiceableBuildings',
        'isExperienceAdvert',
      ]);

      const { newChanges } = deepObjDiff(
        { ...experience, location: location },
        {
          location: {
            ...remainingLocation,
            address: shouldValidateAddress ? rest.address : undefined,
          },
        }
      );
      if (Object.keys(newChanges).length) {
        if (isApproved) {
          setPendingChanges({ changes: newChanges });
          setShowAlertDialog(true);
        } else {
          onConfirmSaveLocation({ changes: newChanges });
          setDrawerOpen(false);
        }
      } else {
        setDrawerOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFormSubmitHost = async (data: AboutHostSectionEditInput) => {
    try {
      const { newCompleteChanges } = deepObjDiff(experience, {
        host: { ...data },
      });
      await updateExperience({
        changes: newCompleteChanges,
        productId: experienceId,
      });
      refetch();
      setDrawerOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onFormSubmitPricing = async (pricingData: PricingFormInput) => {
    try {
      const newChanges: Record<string, object> = {};
      if (!!pricingData.acceptedPurchaseMethods) {
        newChanges['changes'] = {
          acceptedPurchaseMethods: pricingData.acceptedPurchaseMethods,
        };
      }
      // Update prices using the purchasable endpoint when price points are changed
      if (pricingData.prices.length) {
        newChanges['prices'] = PricingWithTaxesInputToUpdateProductPricingDto(
          pricingData.prices
        );
      }
      if (Object.keys(newChanges).length) {
        if (isApproved) {
          setPendingChanges(newChanges);
          setShowAlertDialog(true);
        } else {
          onConfirmSavePricing(newChanges);
          setDrawerOpen(false);
        }
      } else {
        setDrawerOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onConfirmSavePricing = async (data?: {
    changes?: Partial<Experience>;
    prices?: UpdatePriceDto[];
  }) => {
    if (!data) {
      data = pendingChanges || {};
    }
    try {
      if (data) {
        if (data.changes) {
          await updateExperience({
            changes: data.changes,
            productId: experienceId,
          });
        }
        if (data.prices) {
          //TODO - update this to be a mutation
          await apiClient.purchasables.updateProductPrices({
            id: experienceId,
            prices: data.prices,
          });
        }
      }
      refetch();
      setDrawerOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onConfirmSaveLocation = async (data?: {
    changes?: Partial<Experience>;
    location?: Partial<Location>;
  }) => {
    if (!data) {
      data = pendingChanges || {};
    }
    try {
      if (data) {
        await updateExperience({
          changes: data.changes as Partial<Experience>,
          productId: experienceId,
          locationId: experience.location?.id,
        });
        if (data.changes?.location) {
          refetchLocation();
        }
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onConfirmSave = async () => {
    switch (editScreen) {
      case 'location':
        onConfirmSaveLocation();
        break;
      case 'pricing':
        onConfirmSavePricing();
        break;
      default:
        onConfirmSaveGeneric();
        break;
    }
    setPendingChanges(null);
  };

  // Clear the changes after submitting them
  const onConfirmSaveGeneric = async () => {
    try {
      if (pendingChanges) {
        await updateExperience({
          productId: experienceId,
          changes: pendingChanges.changes as Partial<Experience>,
        });
      }
      refetch();
    } catch (error) {
      console.error(error);
    }

    setPendingChanges(null);
  };

  const handleAlertDialogClose = (value: boolean) => {
    setShowAlertDialog(false);
    if (value) {
      onConfirmSave();
      setDrawerOpen(false);
    } else {
      // Discard changes on cancel
      setPendingChanges(null);
      refetch();
    }
  };

  const renderDetailContent = (id: ExperiencesDetailId) => {
    switch (id) {
      case 'submitForApproval':
        return (
          <SubmitForApprovalSection
            experience={experience}
            isCompleted={isCompleted}
            onFormSubmit={() => refetch()}
          />
        );
      case 'basicInfo':
        return <BasicInfoSection experience={experience} />;
      case 'audienceAccess':
        return <AudienceAccessSection experience={experience} />;
      case 'titleAndDescription':
        return <TitleAndDescriptionSection experience={experience} />;
      case 'photos':
        return <PhotoSection experience={experience} />;
      case 'location':
        return <LocationSection location={location} experience={experience} />;
      case 'aboutTheHost':
        return <AboutHostSection experience={experience} />;
      case 'pricing':
        return <PricingSection experience={experience} />;
      case 'capacity':
        return <CapacitySection experience={experience} />;
      case 'amenities':
        return <AmenitiesSection experience={experience} />;
      case 'requirements':
        return <RequirementsSection experience={experience} />;
      case 'requestCommunication':
        return <RequestCommunicationSection experience={experience} />;
      case 'cancelation':
        return <CancelationSection experience={experience} />;
      case 'bookingRequests':
        return <BookingRequestSection experience={experience} />;
      case 'squareConnection':
        return <SquareConnectionSection experience={experience} />;
      case 'advertUrl':
        return <AdvertUrlSection experience={experience} />;
      case 'bookingSchedule':
        return <BookingScheduleSection experience={experience} />;
      default:
        return null;
    }
  };

  const renderEditScreen = () => {
    switch (editScreen) {
      case 'basicInfo':
        return (
          <BasicInfoSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'audienceAccess':
        return (
          <AudienceAccessSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'titleAndDescription':
        return (
          <TitleAndDescriptionSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'photos':
        return (
          <PhotoSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'location':
        return (
          <LocationSectionEdit
            location={location}
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'aboutTheHost':
        return (
          <AboutHostSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'pricing':
        return (
          <PricingSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
            handleCloseDrawer={() => setDrawerOpen(false)}
          />
        );
      case 'capacity':
        return (
          <CapacitySectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'amenities':
        return (
          <AmenitiesSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'requirements':
        return (
          <RequirementsSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'requestCommunication':
        return (
          <RequestCommunicationSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'cancelation':
        return (
          <CancelationSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'squareConnection':
        return (
          <SquareConnectionSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      case 'advertUrl':
        return (
          <AdvertUrlSectionEdit
            experience={experience}
            onFormSubmit={onFormSubmit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container sx={{ paddingY: theme.spacers.xxl4 }}>
      {isApproved && !experience.buildingIds?.length && (
        <MessageBanner type="warn">
          This experience does not have any Permitted Audience assigned, and it
          will not be showing anywhere
        </MessageBanner>
      )}
      {showAlertDialog && (
        <AlertDialog
          open={showAlertDialog}
          onClose={handleAlertDialogClose}
          image={ALERT_DIALOGS[editScreen].image}
          title={ALERT_DIALOGS[editScreen].title}
          message={ALERT_DIALOGS[editScreen].message}
          agree="Save changes"
          disagree="Cancel"
        />
      )}

      <DetailsDrawer
        actions={
          <>
            <Button onClick={onWindowClose} variant="text">
              Cancel
            </Button>
            <Button
              type="submit"
              data-testid="saveExpDeatilsDrawerBtn"
              form={`${editScreen}Form`}
            >
              Save
            </Button>
          </>
        }
        closeWindow={onWindowClose}
        drawerOpen={drawerOpen}
        title={experience.title}
      >
        {renderEditScreen()}
      </DetailsDrawer>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FloatingMenu
            experience={experience}
            ref={menuRef}
            setHeight={setHeight}
            onMenuItemClick={sectionId => {
              setSelectedSectionId(sectionId);
            }}
            title={experience.title}
            merchantType={merchant?.type || MerchantType.STANDARD}
          />
        </Grid>
        <Grid item xs={9} rowSpacing={3}>
          <Grid item xs={12} sx={{ height }}></Grid>
          <Stack spacing={theme.spacers.l}>
            {detailsMenu[selectedSectionId].menu.map(item =>
              item.visible ? (
                <DetailSection
                  key={`${item.id}_section`}
                  id={item.id}
                  experience={experience}
                  {...(item.canEdit && { onEditClick })}
                  emptyImage={item.image}
                  emptyMessage={item.emptyMessage}
                  completeInfoCheck={item.completeInfoCheck}
                  showCompleteInfoCheck={item.showCompleteInfoCheck}
                  title={item.title}
                >
                  {renderDetailContent(item.id)}
                </DetailSection>
              ) : null
            )}
            {detailsMenu[selectedSectionId].includeApprovalSection && (
              <DetailSection
                key="submitForApproval_section"
                id="submitForApproval"
                experience={experience}
              >
                {renderDetailContent('submitForApproval')}
              </DetailSection>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
