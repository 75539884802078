import { BuildingsTenants } from '@/types';
import {
  ArrayMinSize,
  IsArray,
  IsNotEmpty,
  IsObject,
  ValidateIf,
} from 'class-validator';

export class AudienceAccessSectionEditInput {
  @ValidateIf(o => o.hasServiceableBuildings === true)
  @IsNotEmpty()
  @IsArray()
  @ArrayMinSize(1, { message: 'Please select at least one building.' })
  buildingIds: string[];

  @IsNotEmpty()
  @IsArray()
  @IsObject({ each: true })
  buildingsTenants?: BuildingsTenants[];

  hasServiceableBuildings?: boolean;
}
