import { ApiClient } from '@/api/apiClient';
import { LabelField } from '@/components';
import { DialogBox } from '@/components/AlertDialog/AlertDialog.styles';
import { Dialog } from '@/components/Dialog/Dialog';
import {
  MessagingDialog,
  MessagingDialogProps,
} from '@/components/MessagingDialog/MessagingDialog';
import RoundButton from '@/components/RoundButton/Component';
import { useFeatureFlags, useMutationCloseListing } from '@/hooks';
import {
  Booking,
  Listing,
  ListingStatus,
  Location,
  PriceType,
  ProductTypes,
} from '@/types';
import { RecurringEvent } from '@/types/RecurringEvent';
import {
  capitalizeEachWord,
  formatDate,
  formatDateWithDayOfWeek,
  formatPricing,
  formatTime,
  isListingModifiable,
} from '@/utils';
import {
  formatRecurringDaysString,
  formatTimeIntervalString,
} from '@/utils/recurrenceUtils';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  Box,
  MessageBanner,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useMutation } from '@tanstack/react-query';
import { IsNotEmpty } from 'class-validator';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GuestList, HostList } from '../';
import { StyledForm } from '../EditListing/EditListing.styles';
import { RefundDialog, RefundDialogProps } from '../RefundDialog';
import { PRICE_TYPE_MAP } from '@/constants';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

export class ViewListingFormClass {
  @IsNotEmpty({ message: 'Please enter a message' })
  message: string;
}

export class ViewListingCloseInput {
  updateIsEventClosed: boolean;
}

export type ViewListingProps = {
  handleSubmit: (data: ViewListingFormClass) => void;
  listing: Listing;
  venue?: Location | null;
  recurringEvent?: RecurringEvent;
  listingError: string | null;
  experienceType?: ProductTypes;
  onMutationCloseListingSuccess: () => void;
};

export const ViewListing = ({
  handleSubmit,
  listing,
  venue,
  recurringEvent,
  listingError,
  experienceType,
  onMutationCloseListingSuccess,
}: ViewListingProps) => {
  const apiClient = ApiClient();
  const theme = useTheme();
  const [messageBoxInfo, setMessageBoxInfo] = useState<
    MessagingDialogProps | undefined
  >();
  const [errorDownloadingCsv, setErrorDownloadingCsv] = useState(false);
  const [isListingClosed, setIsListingClosed] = useState(
    !!listing.isEventClosed
  );
  const [listingDrawerError, setListingDrawerError] = useState<null | string>(
    null
  );
  const [listingDrawerSuccess, setListingDrawerSuccess] = useState<
    null | string
  >(null);

  const [refundDialogProps, setRefundDialogProps] = useState<
    RefundDialogProps | undefined
  >();

  const {
    isMessageGuestsEnabled,
    isCloseSignUpsEnabled,
    isParticipantsExportToCsvEnabled,
    isRecurringEventsEnabled,
  } = useFeatureFlags();

  const {
    data: bookings,
    isLoading: areBookingsLoading,
    mutate: mutateBookings,
  } = useMutation({
    mutationFn: ({ listingId }: { listingId: string }) => {
      return apiClient.bookings.getAllListingBookings({ listingId });
    },
  });
  const {
    data: cancelledBookings,
    isLoading: areCancelledBookingsLoading,
    mutate: mutateCancelledBookings,
  } = useMutation({
    mutationFn: ({ listingId }: { listingId: string }) => {
      return apiClient.bookings.getAllCancelledListingBookings({ listingId });
    },
  });

  const handleOnMutationError = async (errorMessage?: string) => {
    setListingDrawerSuccess(null);
    setListingDrawerError(
      errorMessage ||
        `Something went wrong.\nWe could not ${
          isListingClosed ? 'open' : 'close'
        } your listing`
    );
  };
  const handleOnMutationSuccess = async () => {
    onMutationCloseListingSuccess();
    setListingDrawerSuccess(
      `You have just ${
        isListingClosed ? 'opened' : 'closed'
      } signups for this event.\nNo users will now be able to sign up.`
    );
    setIsListingClosed(!isListingClosed);
    setListingDrawerError(null);
  };

  const {
    closeListingSignUpsMutation: {
      mutate: closeListing,
      isLoading: isCloseListingLoading,
    },
  } = useMutationCloseListing(
    { listing },
    handleOnMutationSuccess,
    handleOnMutationError
  );

  const handleViewListingClose = async (data: {
    updateIsEventClosed: boolean;
  }) => {
    closeListing(data);
  };

  const resolver = classValidatorResolver(ViewListingFormClass);

  const { handleSubmit: useFormHandleSubmit } = useForm<ViewListingFormClass>({
    resolver,
    mode: 'onTouched',
    values: {
      message: '',
    },
  });

  useEffect(() => {
    if (listing.id) {
      mutateBookings({ listingId: listing.id });
      mutateCancelledBookings({ listingId: listing.id });
    }
  }, []);

  // loading pertinent info to message specific guest
  const handleGuestClick = (booking: Booking) => {
    setMessageBoxInfo({
      messageType: 'one',
      bookingId: booking.consumerId,
      listingId: listing.id,
      firstName: booking.consumer.firstName,
      lastName: booking.consumer.lastName,
    });
  };

  const handleGuestRefund = (booking: Booking) => {
    setRefundDialogProps({
      bookingId: booking.id,
      amount: booking.pricing?.total || 0,
      firstName: booking.consumer.firstName,
      lastName: booking.consumer.lastName,
      onClose: () => setRefundDialogProps(undefined),
      onSuccess: () => {
        mutateBookings({ listingId: listing.id });
        mutateCancelledBookings({ listingId: listing.id });
      },
    });
  };

  const handleCsvDownload = () =>
    // TODO: refactor to be cleaner & better match eslint
    apiClient.bookings
      .getListingParticipantsCsv({ listingId: listing.id })
      .then(
        res => {
          const fileData = res.data;
          const filename = `${listing.title}_${formatDate(listing.startsAt)
            .replace(/,/g, '_')
            .replace(/[ ]/g, '')}.csv`;
          const url = window.URL.createObjectURL(new Blob([fileData]));
          const link = document.createElement('a');
          link.href = url;
          if (typeof window?.navigator?.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(new Blob([fileData]), filename);
          } else {
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          }
        },
        error => {
          setErrorDownloadingCsv(true);
          console.error('Unable to download CSV: ', error);
        }
      );

  // TODO: remove the listing.totalAmount once price is deprecated
  const guestPrice = listing.basePrice?.totalAmount || listing.totalAmount;
  let amountPaidByGuests = '';
  if (guestPrice) {
    amountPaidByGuests = `${formatPricing(guestPrice)}/${
      PRICE_TYPE_MAP[listing.basePrice?.priceType || PriceType.PRICE_PER_PERSON]
    }`;
  } else if (experienceType === ProductTypes.FOOD_SERVICE) {
    amountPaidByGuests = 'Depends on order';
  } else if (listing.basePrice?.priceType === PriceType.MEMBERSHIP) {
    amountPaidByGuests = 'Depends on membership';
  }

  return (
    <>
      {(!!listingError || !!listingDrawerError) && (
        <Box sx={{ mb: 2, 'white-space': 'break-spaces' }}>
          <MessageBanner type="error">
            {listingError || listingDrawerError || 'Something went wrong'}
          </MessageBanner>
        </Box>
      )}
      {!!listingDrawerSuccess && (
        <Box sx={{ mb: 2, 'white-space': 'break-spaces' }}>
          <MessageBanner type="success">{listingDrawerSuccess}</MessageBanner>
        </Box>
      )}
      {listing.status === ListingStatus.MERCHANT_CANCELLED && (
        <Box sx={{ mb: 2 }}>
          <MessageBanner type="error">{`This event was canceled on \n${formatDate(
            listing.updatedAt || ''
          )}`}</MessageBanner>
        </Box>
      )}
      <StyledForm onSubmit={useFormHandleSubmit(handleSubmit)}>
        <Typography variant="h5">
          {listing.title || listing?.productSnapshot?.title}
        </Typography>
        <LabelField
          label="Date"
          value={formatDateWithDayOfWeek(listing.startsAt)}
          footer={
            !!recurringEvent && recurringEvent.repeatDays?.daysOfWeek ? (
              <Typography sx={{ color: theme.palette.primary.medium }}>
                {formatRecurringDaysString(
                  recurringEvent.repeatDays.type || '',
                  recurringEvent.repeatDays.until
                )}
              </Typography>
            ) : undefined
          }
        />
        <LabelField
          label="Time"
          value={`${formatTime(listing.startsAt)} - ${formatTime(
            listing.endsAt
          )} `}
          footer={
            isRecurringEventsEnabled &&
            !!recurringEvent &&
            !!recurringEvent.timeInterval?.intervalSegment ? (
              <Typography sx={{ color: theme.palette.primary.medium }}>
                {formatTimeIntervalString(
                  recurringEvent.timeInterval,
                  recurringEvent.startDateTime,
                  recurringEvent.endDateTime
                )}
              </Typography>
            ) : undefined
          }
        />
        {/* TODO: display lists of host once we have one to many relationship for listing and hosts */}
        <LabelField
          label="Host"
          value={<HostList hosts={listing.host ? [listing.host] : []} />}
        />
        <LabelField label="Amount paid by guests" value={amountPaidByGuests} />
        {(listing.capacity?.max || listing.productSnapshot?.capacity?.max) && (
          <LabelField
            label="Max no. of guests"
            value={(
              listing.capacity?.max ||
              listing.productSnapshot?.capacity?.max ||
              ''
            ).toString()}
          />
        )}
        {venue?.address && (
          <LabelField
            label="Where should guests meet you?"
            value={
              <>
                <Typography>{venue?.address.street}</Typography>
                <Typography>{`${venue?.address.state?.toUpperCase()} ${capitalizeEachWord(
                  venue?.address.city,
                  true
                )} ${venue?.address.zipCode}`}</Typography>
              </>
            }
          />
        )}
        {!!errorDownloadingCsv && (
          <MessageBanner type="error" width="100%">
            Unable to download CSV
          </MessageBanner>
        )}
        {isParticipantsExportToCsvEnabled && !!listing.purchasedBy.length && (
          <RoundButton
            text="Export Sign-up List"
            default="white-navy small"
            hover="blue-baby-blue small"
            type="button"
            handleClick={() => handleCsvDownload()}
          />
        )}
        <GuestList
          bookings={bookings}
          isLoading={areBookingsLoading}
          title="Signups"
          handleClick={handleGuestClick}
          handleRefund={handleGuestRefund}
        />
        {isCloseSignUpsEnabled && isListingModifiable(listing) && (
          <RoundButton
            text={isListingClosed ? 'open sign-ups' : 'close sign-ups'}
            default="white-navy small"
            hover="blue-baby-blue small"
            type="button"
            disabled={isCloseListingLoading}
            handleClick={() =>
              handleViewListingClose({
                updateIsEventClosed: !isListingClosed,
              })
            }
          />
        )}
        {isMessageGuestsEnabled && !!listing.purchasedBy.length && (
          <RoundButton
            text="Email all participants"
            default="white-navy small"
            hover="blue-baby-blue small"
            type="button"
            handleClick={() =>
              // loading pertinent info to message all guests on this listing
              setMessageBoxInfo({ messageType: 'all', listingId: listing.id })
            }
          />
        )}
        {!!messageBoxInfo && (
          <Dialog
            open={!!messageBoxInfo}
            onClose={() => setMessageBoxInfo(undefined)}
          >
            <MessagingDialog {...messageBoxInfo} />
          </Dialog>
        )}
        {!!refundDialogProps && (
          <DialogBox
            PaperProps={{
              sx: {
                maxWidth: '600px',
                maxHeight: '80%',
                height: 'auto',
              },
            }}
            open={!!refundDialogProps}
            onClose={() => setRefundDialogProps(undefined)}
          >
            <RefundDialog {...refundDialogProps} />
          </DialogBox>
        )}
        {cancelledBookings?.length ? (
          <GuestList
            bookings={cancelledBookings}
            isLoading={areCancelledBookingsLoading}
            title="Cancellations"
            handleClick={handleGuestClick}
            handleRefund={handleGuestRefund}
          />
        ) : null}
      </StyledForm>
    </>
  );
};
