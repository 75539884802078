import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { Amenity, AmenityPrice, AmenityType, Experience } from '@/types';
import { AMENITIES_ICON_MAP, PRICE_TYPE_MAP } from '@/constants';
import { formatPricing } from '@/utils';

interface AmenitiesSectionProps {
  experience: Experience;
}

export const AmenitiesSection = ({ experience }: AmenitiesSectionProps) => {
  const amenities = (experience?.amenities || [])?.filter(
    amenity => amenity.type === AmenityType.INCLUDED
  );
  const addOns = (experience?.amenities || [])?.filter(
    addOn =>
      addOn.type === AmenityType.ADD_ON ||
      addOn.type === AmenityType.CUSTOM_ADD_ON
  ) as (Amenity & { pricing: AmenityPrice })[]; // Making pricing section non optional

  return (
    <Stack spacing={4}>
      {experience.bookingRequestInfo?.info && (
        <>
          <Typography variant="h5">Customer more details request</Typography>
          <Typography variant="body1">
            {experience.bookingRequestInfo?.info}
          </Typography>
        </>
      )}
      {amenities.length > 0 && (
        <>
          <Typography variant="h6">Amenities</Typography>
          <List sx={{ paddingTop: 0 }}>
            {amenities.map(amenity => {
              const AmenityIcon = AMENITIES_ICON_MAP[amenity.icon];
              return (
                <ListItem sx={{ paddingLeft: 0 }} key={amenity.name}>
                  {amenity.icon && <AmenityIcon width={24} height={24} />}
                  <ListItemText sx={{ ml: 1 }} primary={amenity.name} />
                </ListItem>
              );
            })}
          </List>
        </>
      )}

      {addOns.length > 0 && (
        <>
          <Typography variant="h6">Add-ons</Typography>
          <List>
            {addOns.map(addOn => {
              const AddOnIcon = AMENITIES_ICON_MAP[addOn.icon];
              return (
                <ListItem
                  sx={{
                    paddingLeft: 0,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                  key={addOn.name}
                >
                  <Box display="flex" alignItems="center">
                    {AddOnIcon ? <AddOnIcon width={24} height={24} /> : null}
                    <ListItemText sx={{ ml: 1 }} primary={addOn.name} />
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {`${formatPricing(addOn.pricing.totalAmount)} / ${
                      PRICE_TYPE_MAP[addOn.pricing.priceType]
                    }`}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </Stack>
  );
};
