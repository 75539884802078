import { Tenant, TenantShort } from '@/types';
import { AxiosInstance } from 'axios';

export interface GetTenantsQueryParams {
  buildingIds: string[];
}

export const endpoints = {
  getTenants() {
    return '/inspire-api/tenants';
  },
  getTenantShort() {
    return '/inspire-api/tenants/short';
  },
};

export const inspireAPI = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getTenants: async (params: GetTenantsQueryParams): Promise<Tenant[]> => {
      // buildingIds: ["1", "2", "3"] --> "1,2,3"
      const buildingIds = params.buildingIds.join(',');
      const { data } = await axiosInstance.get(endpoints.getTenants(), {
        params: { buildingIds },
      });
      return data;
    },
    getTenantShort: async (
      params: GetTenantsQueryParams
    ): Promise<TenantShort[]> => {
      // buildingIds: ["1", "2", "3"] --> "1,2,3"
      const buildingIds = params.buildingIds.join(',');
      const { data } = await axiosInstance.get(endpoints.getTenantShort(), {
        params: { buildingIds },
      });
      return data;
    },
  };
};
