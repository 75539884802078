import {
  Stack,
  Typography,
  Box,
  FormControl,
  Autocomplete,
} from '@silverstein-properties/inspirelabs-ui';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { BuildingSelect, Experience, TenantShort } from '@/types';
import { LabelField } from '@/components';
import { FormHelperText, TextField } from '@mui/material';
import {
  useMerchantServiceableBuildings,
  useTenantsFromBuildings,
} from '@/hooks';
import { useAuth } from '@/hooks';
import { AudienceAccessSectionEditInput } from '@/classes';

interface BuildingDropdownOption extends BuildingSelect {
  label: string;
}

interface TenantDropdownOption extends TenantShort {
  label: string;
  isAllTenantsOption?: boolean;
}

const resolver = classValidatorResolver(AudienceAccessSectionEditInput);

export type AudienceAccessSectionEditProps = {
  experience: Experience;
  onFormSubmit: (data: AudienceAccessSectionEditInput) => Promise<void>;
};

export const AudienceAccessSectionEdit = ({
  experience,
  onFormSubmit,
}: AudienceAccessSectionEditProps) => {
  const { data: user } = useAuth();
  const { data: serviceableBuildings } = useMerchantServiceableBuildings(
    user?.primaryMerchantId || ''
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AudienceAccessSectionEditInput>({
    resolver,
    defaultValues: {
      buildingIds: experience.buildingIds || [],
      buildingsTenants: experience.buildingsTenants || [],
      hasServiceableBuildings:
        !!serviceableBuildings && serviceableBuildings.length > 0,
    },
  });

  const onSubmit = (data: AudienceAccessSectionEditInput) => {
    onFormSubmit(data);
  };

  const buildingDropdownOptions: BuildingDropdownOption[] =
    serviceableBuildings.map(building => ({
      ...building,
      label: building.name,
    })) || [];

  const selectedBuildingIds = watch('buildingIds') || [];

  // Fetch tenants for selected buildings
  const { data: tenants } = useTenantsFromBuildings(selectedBuildingIds);

  // Need to update both `buildingIds` and `buildingsTenants`
  const handleBuildingChange = (newValues: BuildingDropdownOption[]) => {
    setValue(
      'buildingIds',
      newValues.map(building => building.id.toString())
    );

    const newBuildingsTenants = newValues.map(building => ({
      [building.id.toString()]: [],
    }));
    setValue('buildingsTenants', newBuildingsTenants);
  };

  const handleTenantsChange = (
    buildingId: string,
    newValues: TenantDropdownOption[]
  ) => {
    const currentBuildingsTenants = watch('buildingsTenants') || [];
    const buildingIndex = currentBuildingsTenants.findIndex(buildingTenant => {
      const [currentBuildingId] = Object.keys(buildingTenant);
      return currentBuildingId === buildingId;
    });

    if (buildingIndex > -1) {
      currentBuildingsTenants[buildingIndex] = {
        [buildingId]: newValues.map(tenant => tenant.id),
      };
      setValue('buildingsTenants', currentBuildingsTenants);
    }
  };

  // a SELECT ALL option that represents all tenants in a building
  const selectAllTenantsOption: TenantDropdownOption = {
    id: 'all',
    label: 'All tenants',
    name: 'All tenants',
    buildings: [],
    isAllTenantsOption: true,
  };

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Audience Access</Typography>
      <form id="audienceAccessForm" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} width="100%">
          {/* ============ Buildings Selection ============ */}
          <LabelField
            label="What buildings will this experience be available in?"
            value={
              buildingDropdownOptions.length > 0 ? (
                <Box sx={{ display: 'inline-flex', mt: 2, width: '100%' }}>
                  <Controller
                    name="buildingIds"
                    control={control}
                    render={({ field: { value, ...field } }) => {
                      const selectedBuildings = buildingDropdownOptions.filter(
                        building => value?.includes(building.id.toString())
                      );

                      return (
                        <FormControl fullWidth>
                          <Autocomplete
                            {...field}
                            data-testid="buildingIds"
                            multiple
                            disableCloseOnSelect
                            options={buildingDropdownOptions}
                            value={selectedBuildings}
                            onChange={(_, newValue) => {
                              handleBuildingChange(
                                newValue as BuildingDropdownOption[]
                              );
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Building"
                                error={!!errors.buildingIds}
                              />
                            )}
                          />
                          <FormHelperText error={!!errors.buildingIds}>
                            {errors.buildingIds?.message}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                </Box>
              ) : (
                'No buildings available. Please contact support to add buildings to your account.'
              )
            }
          />

          {/* ============ Tenants Selection for each building ============ */}
          {selectedBuildingIds.map(buildingId => {
            const building = serviceableBuildings.find(
              building => building.id.toString() === buildingId
            );
            const currentBuildingsTenants = watch('buildingsTenants') || [];
            const selectedTenantIds =
              currentBuildingsTenants.find(
                bt => Object.keys(bt)[0] === buildingId
              )?.[buildingId] || [];

            // Convert tenant data to TenantOption type
            const tenantDropdownOptions: TenantDropdownOption[] =
              tenants
                .filter(tenant =>
                  tenant.buildings.find(building => building.id === buildingId)
                )
                ?.map(tenant => ({
                  ...tenant,
                  id: tenant.id,
                  label: tenant.name,
                })) || [];

            const selectedTenants = tenantDropdownOptions.filter(tenant =>
              selectedTenantIds.includes(tenant.id)
            );

            return (
              building && (
                <LabelField
                  key={buildingId}
                  label={`Select tenants for ${building.name}`}
                  value={
                    <Box sx={{ display: 'inline-flex', mt: 2, width: '100%' }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          options={[
                            selectAllTenantsOption,
                            ...tenantDropdownOptions,
                          ]}
                          value={selectedTenants}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(_, newValue) => {
                            if (!newValue) {
                              return;
                            }
                            const values = newValue as TenantDropdownOption[];

                            // If the user selects "All tenants", pick the whole array of `tenantDropdownOptions`
                            if (
                              values.some(
                                (option: TenantDropdownOption) =>
                                  option.isAllTenantsOption === true
                              )
                            ) {
                              handleTenantsChange(
                                buildingId,
                                tenantDropdownOptions // all tenants
                              );
                            } else {
                              handleTenantsChange(
                                buildingId,
                                values as TenantDropdownOption[] // some selected tenants
                              );
                            }
                          }}
                          renderInput={params => (
                            <TextField {...params} label="Tenants" />
                          )}
                        />
                      </FormControl>
                    </Box>
                  }
                />
              )
            );
          })}
        </Stack>
      </form>
    </Stack>
  );
};
