import { AxiosInstance } from 'axios';
import { Amenity, AmenityType } from '@/types';

export const endpoints = {
  allAmenities() {
    return '/amenities';
  },
  amenitiesByType(type: AmenityType) {
    return `/amenities/${type}`;
  },
};

export const amenities = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getAllAmenities: async (): Promise<Amenity[]> => {
      const { data } = await axiosInstance.get(endpoints.allAmenities());
      return data;
    },
    getAmenitiesByType: async (type: AmenityType): Promise<Amenity[]> => {
      const { data } = await axiosInstance.get(endpoints.amenitiesByType(type));
      return data;
    },
  };
};
