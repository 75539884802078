import { Amenity, AmenityPriceType, AmenityType } from '@/types';
import { Transform, Type } from 'class-transformer';
import {
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

export class AddOnFormItem {
  id?: string;

  @IsString()
  @IsNotEmpty({
    message: 'Please enter a valid add-on name',
  })
  name: string;

  @IsEnum(AmenityPriceType)
  @IsNotEmpty({
    message: 'Please select a valid add-on unit',
  })
  unit?: AmenityPriceType;

  @Transform(({ value }) => Number(value))
  @IsNumber()
  @IsNotEmpty({
    message: 'Please enter a valid add-on price',
  })
  price?: number;

  @IsEnum(AmenityType)
  @IsNotEmpty({
    message: 'Please select a valid add-on type',
  })
  type: AmenityType;

  @IsOptional()
  icon?: string;
}

export class AmenitiesSectionEditInput {
  @IsArray()
  amenities: Amenity[];

  @IsArray()
  @IsObject({
    each: true,
  })
  @ValidateNested({
    each: true,
  })
  @Type(() => AddOnFormItem)
  addOns: AddOnFormItem[];

  @IsArray()
  @IsObject({
    each: true,
  })
  @ValidateNested({
    each: true,
  })
  @Type(() => AddOnFormItem)
  customAddOns: AddOnFormItem[];

  @IsString()
  @IsOptional()
  initialDetailsPrompt?: string;
}
