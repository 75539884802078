import { Experience } from '@/types';
import { Stack, Chip } from '@silverstein-properties/inspirelabs-ui';
import {
  useAuth,
  useMerchantServiceableBuildings,
  useTenantsFromBuildings,
} from '@/hooks';
import { Typography } from '@mui/material';

export type AudienceAccessSectionProps = {
  experience: Experience;
};

export const AudienceAccessSection = ({
  experience,
}: AudienceAccessSectionProps) => {
  const { data: user } = useAuth();
  const { data: serviceableBuildings } = useMerchantServiceableBuildings(
    user?.primaryMerchantId || ''
  );
  // Get tenants for the buildings this experience is available in
  const { data: tenants } = useTenantsFromBuildings(
    experience.buildingIds || []
  );

  if (!experience.buildingIds?.length) {
    return (
      <Stack spacing={4}>
        <Typography variant="body2" color="text.secondary">
          No Permitted Audience Selected
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={4}>
      {experience.buildingsTenants?.map(buildingTenant => {
        // Each buildingTenant object has a single key (buildingId) mapping to array of tenant IDs
        const buildingId = Object.keys(buildingTenant)[0];
        const selectedTenantIds = buildingTenant[buildingId];

        const buildingObject = serviceableBuildings.find(
          building => building.id.toString() === buildingId
        );
        // Get all tenants belong to this building
        const tenantsFromCurrentBuilding = tenants.filter(tenant =>
          tenant.buildings?.some(
            building => building.id.toString() === buildingId
          )
        );
        const selectedTenantObjects = tenants.filter(tenant =>
          selectedTenantIds.includes(tenant.id)
        );

        // Display "All tenants" if every tenant in the building is selected
        const isAllTenantsSelected =
          (tenantsFromCurrentBuilding.length ?? 0) > 0 &&
          tenantsFromCurrentBuilding.length === selectedTenantObjects.length;

        return buildingObject ? (
          <Stack key={buildingId} spacing={0.5}>
            <Typography variant="subtitle1">{buildingObject.name}</Typography>
            <Stack direction="row" spacing={0.5} flexWrap="wrap">
              {isAllTenantsSelected ? (
                <Chip label="All tenants" />
              ) : (
                selectedTenantObjects.map(tenant => (
                  <Chip key={tenant.id} label={tenant.name} />
                ))
              )}
              {(!selectedTenantObjects ||
                selectedTenantObjects.length === 0) && (
                <Typography variant="body2" color="text.secondary">
                  No tenants selected for this building
                </Typography>
              )}
            </Stack>
          </Stack>
        ) : null;
      })}
    </Stack>
  );
};
