import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience, ProductTypes } from '@/types';
import { LabelField } from '@/components';
import { TEXT_AREA_LIMIT_LONG, TEXT_AREA_LIMIT_SHORT } from '@/constants';
import { HOURS_OPTIONS, MINUTES_OPTIONS } from '@/constants';
import {
  Stack,
  Typography,
  InputField,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@silverstein-properties/inspirelabs-ui';
import { renderInputFieldHelperText } from '@/utils';
import { TitleAndDescriptionSectionEditInput } from '@/classes/TitleAndDescriptionSectionEditInput';
import { FormHelperText } from '@mui/material';

const resolver = classValidatorResolver(TitleAndDescriptionSectionEditInput, {
  transformer: { enableImplicitConversion: true },
});

export type TitleAndDescriptionSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: TitleAndDescriptionSectionEditInput) => Promise<void>;
};

export const TitleAndDescriptionSectionEdit = ({
  experience,
  onFormSubmit,
}: TitleAndDescriptionSectionEditPropsType) => {
  const isAdvert = experience.type === ProductTypes.ADVERT;
  const isSpace = experience.type === ProductTypes.SPACE;

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<TitleAndDescriptionSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      title: experience.title || '',
      internalName: experience.internalName || '',
      subtitle: experience.subtitle || '',
      description: experience.description || '',
      duration: {
        hours: experience.duration?.hours?.toString() || '',
        minutes: experience.duration?.minutes?.toString() || '',
      },
      productType: experience.type,
    },
  });

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Title & Description</Typography>
      <form id="titleAndDescriptionForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4} width="100%">
          <LabelField
            label="Edit title"
            value={
              <Box sx={{ display: 'inline-flex', mt: 2 }}>
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      fullWidth
                      label="Title"
                      data-testid="aboutExpTitle"
                      error={!!errors.title}
                      helperText={renderInputFieldHelperText(
                        watch('title')?.length,
                        TEXT_AREA_LIMIT_SHORT,
                        errors.title?.message
                      )}
                    />
                  )}
                />
              </Box>
            }
          />
          <LabelField
            label="Edit internal name"
            value={
              <Box sx={{ display: 'inline-flex', mt: 2 }}>
                <Controller
                  control={control}
                  name="internalName"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      fullWidth
                      label="Internal name"
                      data-testid="aboutExpInternalName"
                      error={!!errors.internalName}
                      helperText="Optional. This won't be visible to your guests."
                    />
                  )}
                />
              </Box>
            }
          />
          <LabelField
            label="Edit subtitle"
            value={
              <Box sx={{ display: 'inline-flex', mt: 2 }}>
                <Controller
                  control={control}
                  name="subtitle"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      fullWidth
                      label="Subtitle"
                      data-testid="aboutExpSubtitle"
                      error={!!errors.subtitle}
                      helperText={renderInputFieldHelperText(
                        watch('subtitle')?.length,
                        TEXT_AREA_LIMIT_SHORT,
                        errors.subtitle?.message
                      )}
                    />
                  )}
                />
              </Box>
            }
          />
          <LabelField
            label="Edit description"
            value={
              <Box sx={{ display: 'inline-flex', mt: 2 }}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      fullWidth
                      multiline
                      rows={5}
                      label="Description"
                      data-testid="aboutExpDescription"
                      error={!!errors.description}
                      helperText={renderInputFieldHelperText(
                        watch('description')?.length,
                        TEXT_AREA_LIMIT_LONG,
                        errors.description?.message
                      )}
                    />
                  )}
                />
              </Box>
            }
          />
          {!(isAdvert || isSpace) && (
            <LabelField
              label="Length of service"
              value={
                <Stack flexDirection="row" mt={2}>
                  <Controller
                    name="duration.hours"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>Hours</InputLabel>
                        <Select
                          {...field}
                          data-testid="aboutExpDurationHours"
                          label="Hours"
                          fullWidth
                          error={!!errors.duration?.hours}
                          renderValue={(selected: string) => {
                            if (!selected) {
                              return '';
                            }
                            const selectedDurationHour = HOURS_OPTIONS.find(
                              durationHour => durationHour.value === selected
                            );
                            return selectedDurationHour?.label || '';
                          }}
                        >
                          {HOURS_OPTIONS.map(durationHour => (
                            <MenuItem
                              key={durationHour.value}
                              value={durationHour.value}
                            >
                              {durationHour.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.duration?.hours}>
                          {!!errors.duration?.hours
                            ? errors.duration.hours?.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="duration.minutes"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>Minutes</InputLabel>
                        <Select
                          {...field}
                          data-testid="aboutExpDurationMinutes"
                          fullWidth
                          label="Minutes"
                          error={!!errors.duration?.minutes}
                          renderValue={(selected: string) => {
                            if (!selected) {
                              return '';
                            }
                            const selectedDurationHour = MINUTES_OPTIONS.find(
                              durationMinute =>
                                durationMinute.value === selected
                            );
                            return selectedDurationHour?.label || '';
                          }}
                        >
                          {MINUTES_OPTIONS.map(durationMinute => (
                            <MenuItem
                              key={durationMinute.value}
                              value={durationMinute.value}
                            >
                              {durationMinute.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.duration?.minutes}>
                          {!!errors.duration?.minutes
                            ? errors.duration?.minutes?.message
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Stack>
              }
            />
          )}
        </Stack>
      </form>
    </Stack>
  );
};
