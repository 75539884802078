import { PricingFormInput } from '@/classes';
import {
  DEFAULT_PROCESSING_FEE,
  PRICE_TYPE_MAP,
  TAX_EXEMPT_PRICING_NO_LABEL,
} from '@/constants';
import { useTaxRates } from '@/hooks';
import {
  calculatePlatformFeeInDollars,
  formatPricing,
  formatTo2DecimalPlaces,
  mapTaxRateOptions,
  parseFloatIfString,
} from '@/utils';
import {
  Grid,
  InfoIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormWatch,
} from 'react-hook-form';
import {
  LeftStyledInputField,
  RightStyledInputField,
  StyledTable,
} from './PricingWithTaxes.styles';
import { FormHelperText, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { PriceType } from '@/types';

interface PricingWithTaxesProps<T extends FieldValues> {
  control: Control<T>;
  fieldIndex: number;
  formState: FormState<T>;
  watch: UseFormWatch<T>;
  disabled?: boolean;

  platformFeeRaw?: number;
  title?: string;
}

interface PricingBreakdownProps {
  platformFee: number;
  processingFeeInDollars: number;
  totalPrice: number;
  salesTaxAmount?: number;
}

const PricingBreakdown = ({
  platformFee,
  processingFeeInDollars,
  totalPrice,
  salesTaxAmount = 0,
}: PricingBreakdownProps) => {
  if (totalPrice === 0) {
    return <>Free</>;
  }
  const totalPlatformFee = platformFee + processingFeeInDollars;
  return (
    <StyledTable>
      <tbody>
        <tr>
          <td>Guest Price</td>
          <td>{formatPricing(totalPrice, 2)}</td>
        </tr>
        <tr>
          <td>Tax</td>
          <td>{formatPricing(-salesTaxAmount, 2)}</td>
        </tr>
        <tr>
          <td>Platform Fee</td>
          <td>{formatPricing(-totalPlatformFee, 2)}</td>
        </tr>
        <tr>
          <td>Net Revenue</td>
          <td>
            {formatPricing(totalPrice - salesTaxAmount - totalPlatformFee, 2)}
          </td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export const PricingWithTaxes = ({
  platformFeeRaw,
  title,
  control,
  fieldIndex,
  formState,
  watch,
  disabled,
}: PricingWithTaxesProps<PricingFormInput>) => {
  const { errors } = formState;

  const { data: taxRates } = useTaxRates();
  const theme = useTheme();

  const price = parseFloatIfString(watch(`prices.${fieldIndex}.amount`));
  const isInputEmpty = watch(`prices.${fieldIndex}.amount`) === '';
  const taxRate = watch(`prices.${fieldIndex}.taxRateId`) || '0';
  const taxPercentage = taxRates?.find(
    oneTaxRate => oneTaxRate.id === taxRate
  )?.percentage;
  const salesTaxAmount =
    !!taxRates && !!taxPercentage
      ? formatTo2DecimalPlaces((price * taxPercentage) / 100)
      : 0;
  const totalPrice = salesTaxAmount + price;
  const priceType = watch(`prices.${fieldIndex}.priceType`);

  const processingFeeInDollars = taxPercentage
    ? formatTo2DecimalPlaces(
        (price * taxPercentage * (DEFAULT_PROCESSING_FEE / 10000)) / 100
      )
    : 0;

  const [platformFee, setPlatformFee] = useState(
    platformFeeRaw ? calculatePlatformFeeInDollars(platformFeeRaw, price) : 0
  );

  // Re-calculate fees when totalPrice/eventType changes
  useEffect(() => {
    if (platformFeeRaw) {
      const platformFeeInDollars = calculatePlatformFeeInDollars(
        platformFeeRaw,
        price
      );
      setPlatformFee(platformFeeInDollars);
    }
  }, [price, platformFeeRaw]);

  return (
    <Grid
      container
      sx={{
        // Disable the pricing with taxes component
        opacity: disabled ? 0.3 : 1,
        pointerEvents: disabled ? 'none' : 'initial',
      }}
    >
      <Grid item xs={12}>
        <Typography marginBottom={theme.spacers.s} variant="subtitle2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name={`prices.${fieldIndex}.amount`}
          render={({ field }) => (
            <LeftStyledInputField
              {...field}
              inputProps={{ 'data-testid': field.name }}
              label={`Price/${
                PRICE_TYPE_MAP[priceType || PriceType.PRICE_PER_PERSON]
              }`}
              isPriceInput
              type="number"
              helperText={errors.prices?.[fieldIndex]?.amount?.message}
              error={!!errors.prices?.[fieldIndex]?.amount}
              disabled={disabled}
              endAdornment={
                !isInputEmpty ? (
                  <InputAdornment position="start">
                    <Tooltip
                      title={
                        <PricingBreakdown
                          platformFee={platformFee}
                          processingFeeInDollars={processingFeeInDollars}
                          totalPrice={totalPrice}
                          salesTaxAmount={salesTaxAmount}
                        />
                      }
                      placement="left"
                      arrow
                    >
                      <InfoIcon
                        width={24}
                        color={theme.palette.primary.medium}
                      />
                    </Tooltip>
                  </InputAdornment>
                ) : null
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name={`prices.${fieldIndex}.taxRateId`}
          render={({ field }) => (
            <RightStyledInputField
              {...field}
              inputProps={{ 'data-testid': field.name }}
              select
              disabled={totalPrice === 0}
              dropdownItems={
                taxRates
                  ? [
                      TAX_EXEMPT_PRICING_NO_LABEL,
                      ...mapTaxRateOptions(taxRates, false),
                    ]
                  : [{ id: 0, label: 'Loading...', value: '0' }]
              }
              label="Tax rate"
              error={!!errors.prices?.[fieldIndex]?.taxRateId}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <Controller
          control={control}
          name={`prices.${fieldIndex}.priceType`}
          render={({ field }) => (
            <>
              <input {...field} type="hidden" />
              <FormHelperText error={!!errors.prices?.[fieldIndex]?.priceType}>
                {errors.prices?.[fieldIndex]?.priceType?.message}
              </FormHelperText>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};
