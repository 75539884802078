import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InfoIcon,
  InputField,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  ExperienceEventType,
  PriceType,
  ProductTypes,
  PurchaseMethods,
  TaxRate,
} from '@/types';
import {
  formatPercentage,
  formatTo2DecimalPlaces,
  parseFloatIfString,
} from '@/utils';
import {
  DEFAULT_PROCESSING_FEE,
  PRICE_TYPE_MAP,
  PRICE_UNIT_CHOICES,
  TAX_EXEMPT_PRICING,
} from '@/constants';
import { AddListingInput, EditListingInput, PricingInput } from '@/classes';
import { useEffect, useState } from 'react';
import { FormGroup, FormHelperText, Grid } from '@mui/material';
import { useTaxRates } from '@/hooks';
import {
  LeftStyledInputField,
  RightStyledInputField,
} from './PricingComponent.styles';

interface PricingComponentProps<T extends FieldValues> {
  control: Control<T>;
  formState: FormState<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  platformFeeRaw?: number;
  isInsideDrawer?: boolean;
  hideTitle?: boolean;
  hidePaymentMethods?: boolean;
  productType?: ProductTypes;
}

export const mapTaxRateOptions = (taxRates: TaxRate[]) => {
  return taxRates.map(taxRate => {
    return {
      id: taxRate.id,
      value: taxRate.id,
      label: `${taxRate.displayName} - ${taxRate.percentage}%`,
    };
  });
};

export const PricingComponent = ({
  control,
  formState,
  setValue,
  watch,
  platformFeeRaw,
  isInsideDrawer = false,
  hideTitle = false,
  hidePaymentMethods = false,
  productType,
}: PricingComponentProps<
  PricingInput | AddListingInput | EditListingInput
>) => {
  const { errors } = formState;
  const { data: taxRates } = useTaxRates();

  const netPrice = parseFloatIfString(watch('price'));
  const totalPrice = parseFloatIfString(watch('totalAmount'));
  const taxRate = watch('taxRateId') || '0';
  const taxPercentage = taxRates?.find(
    oneTaxRate => oneTaxRate.id === taxRate
  )?.percentage;
  const salesTaxAmount =
    !!taxRates && !!taxPercentage
      ? formatTo2DecimalPlaces((totalPrice * taxPercentage) / 100)
      : undefined;
  const priceType = watch('priceType');

  // E.g. platformFee = 15% x totalPrice
  const calculatePlatformFeeInDollars = (
    platformFeeRaw: number,
    totalPrice: number
  ) => {
    return (platformFeeRaw / 10000) * totalPrice;
  };

  const processingFeeInDollars = taxPercentage
    ? formatTo2DecimalPlaces(
        (totalPrice * taxPercentage * (DEFAULT_PROCESSING_FEE / 10000)) / 100
      )
    : 0;

  const theme = useTheme();
  const [platformFee, setPlatformFee] = useState(
    platformFeeRaw
      ? calculatePlatformFeeInDollars(platformFeeRaw, totalPrice)
      : 0
  );
  const [eventType, setEventType] = useState(
    totalPrice === 0
      ? ExperienceEventType.FREE.toString()
      : ExperienceEventType.PAID.toString()
  );

  // This component is used in multiple contexts, hide tax rate selection when scheduling events
  const isTaxInputShowable =
    eventType === ExperienceEventType.PAID && !!isInsideDrawer;

  const isSpaceProduct = productType === ProductTypes.SPACE;

  // Re-calculate fees when totalPrice/eventType changes
  // NOTE: Need to trim the prices to 2 decimal places to match the validation rule in the BE
  // File: src/products/dto/create-product.ts - line 130
  useEffect(() => {
    if (platformFeeRaw) {
      const platformFeeInDollars = calculatePlatformFeeInDollars(
        platformFeeRaw,
        totalPrice
      );
      setPlatformFee(formatTo2DecimalPlaces(platformFeeInDollars));

      setValue(
        'price',
        formatTo2DecimalPlaces(totalPrice - platformFeeInDollars)
      );
      setValue('totalAmount', formatTo2DecimalPlaces(totalPrice));
    }
  }, [totalPrice]);

  useEffect(() => {
    if (eventType === ExperienceEventType.FREE) {
      setPlatformFee(0);
      setValue('price', 0);
      setValue('totalAmount', 0);
    }
  }, [eventType]);

  const handleEventTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEventType(event.target.value);
  };

  return (
    <>
      {!hideTitle && (
        <Typography
          variant={isInsideDrawer ? 'h5' : 'h4'}
          marginBottom={isInsideDrawer ? theme.spacers.xxl : theme.spacers.xxl2}
        >
          Pricing
        </Typography>
      )}

      {/* SELECT EVENT TYPE */}
      <Stack
        width="100%"
        spacing={theme.spacers.m}
        marginBottom={theme.spacers.xl}
      >
        <Typography variant="subtitle1">
          Is this a free or paid event?
        </Typography>

        <Box>
          <FormControl>
            <RadioGroup value={eventType} onChange={handleEventTypeChange}>
              <FormControlLabel
                label="This is a free event"
                data-testid="freeEventBtn"
                value="free"
                control={<Radio />}
              />
              <FormControlLabel
                label="This is a paid event"
                data-testid="paidEventBtn"
                value="paid"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        {eventType === ExperienceEventType.PAID && (
          <Grid container>
            <Grid item xs={isSpaceProduct ? 6 : 12}>
              <Controller
                control={control}
                name="totalAmount"
                data-testid="totalAmount"
                render={({ field }) => (
                  <LeftStyledInputField
                    {...field}
                    label={`Amount/${
                      PRICE_TYPE_MAP[priceType || PriceType.PRICE_PER_PERSON]
                    }`}
                    data-testid="totalAmount"
                    isPriceInput
                    type="number"
                    helperText={errors.totalAmount?.message}
                    error={!!errors.totalAmount}
                    isSpaceProduct={isSpaceProduct}
                  />
                )}
              />
            </Grid>
            {isSpaceProduct && (
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="priceType"
                  data-testid="priceType"
                  render={({ field }) => (
                    <>
                      <RightStyledInputField
                        {...field}
                        label="Price unit"
                        select
                        dropdownItems={PRICE_UNIT_CHOICES}
                      />
                      <FormHelperText error={!!errors.priceType}>
                        {errors.priceType?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>
            )}
          </Grid>
        )}

        {!!isTaxInputShowable && (
          <>
            <Typography variant="subtitle1">Is this event taxable?</Typography>
            <Controller
              control={control}
              name="taxRateId"
              render={({ field }) => (
                <InputField
                  {...field}
                  select
                  dropdownItems={
                    taxRates
                      ? [TAX_EXEMPT_PRICING, ...mapTaxRateOptions(taxRates)]
                      : [{ id: 0, label: 'Loading...', value: '0' }]
                  }
                  label="Tax rate"
                  error={!!errors?.taxRateId}
                  helperText={
                    errors.taxRateId
                      ? errors.taxRateId.message
                      : 'If product is untaxed, select "Exempt"'
                  }
                />
              )}
            />
          </>
        )}
        <Box>
          {/* GUEST PRICE */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            padding={`${theme.spacers.s} 0`}
          >
            <Typography variant="body2">Guest price</Typography>
            <Typography variant="body2">
              $
              {!!salesTaxAmount
                ? formatTo2DecimalPlaces(salesTaxAmount + totalPrice)
                : totalPrice}
            </Typography>
          </Stack>

          {/* Taxes */}
          {!!salesTaxAmount && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={`${theme.spacers.s} 0`}
            >
              <Typography variant="body2">Sales Tax</Typography>
              <Typography variant="body2">-${salesTaxAmount}</Typography>
            </Stack>
          )}

          {/* PLATFORM FEE */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            padding={`${theme.spacers.s} 0`}
          >
            <Stack direction="row" spacing={theme.spacers.xs}>
              <Typography variant="body2">
                {formatPercentage(platformFeeRaw)} platform fee
              </Typography>
              <Tooltip
                arrow
                title={`Merchant earnings are subject to a ${formatPercentage(
                  platformFeeRaw
                )} platform fee based on the guest price.`}
                placement="top"
              >
                <InfoIcon
                  width={theme.constants.ICON_SIZE.small}
                  color={theme.palette.primary.medium}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </Stack>
            <Typography variant="body2">
              -$
              {formatTo2DecimalPlaces(platformFee + processingFeeInDollars)}
            </Typography>
          </Stack>

          <Divider sx={{ bgcolor: 'primary.main', opacity: 1 }} />

          {/* NET REVENUE */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            padding={`${theme.spacers.s} 0`}
          >
            <Typography variant="subtitle2">Net revenue</Typography>
            <Typography data-testid="netRevenue" variant="subtitle2">
              $
              {!!taxRate
                ? formatTo2DecimalPlaces(netPrice - processingFeeInDollars)
                : netPrice}
            </Typography>
          </Stack>
        </Box>

        {/* PAYMENT METHODS */}
        {!hidePaymentMethods && (
          <Controller
            control={control}
            name="acceptedPurchaseMethods"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    // NOTE: Need to do it this way because currently design only has a toggle checkbox but BE expect an array of possible values
                    <Checkbox
                      {...field}
                      checked={field.value?.includes(
                        PurchaseMethods.SUBSCRIPTION
                      )}
                      onChange={event => {
                        setValue(
                          'acceptedPurchaseMethods',
                          event.target.checked
                            ? [PurchaseMethods.SUBSCRIPTION]
                            : [PurchaseMethods.ONE_TIME_MONEY_PURCHASE]
                        );
                      }}
                    />
                  }
                  label="Make available only through subscription"
                  sx={{ marginRight: 0 }}
                />
              </FormGroup>
            )}
          />
        )}
      </Stack>
    </>
  );
};
