import { InputField, styled } from '@silverstein-properties/inspirelabs-ui';

export type LeftStyledInputFieldProps = {
  isSpaceProduct: boolean;
};

export const LeftStyledInputField = styled(InputField, {
  shouldForwardProp: prop => prop !== 'isSpaceProduct',
})<LeftStyledInputFieldProps>(({ isSpaceProduct }) => ({
  '& fieldset': isSpaceProduct && {
    borderRightColor: 'transparent',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const RightStyledInputField = styled(InputField)(() => ({
  '& fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));
