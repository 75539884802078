import { InputField, styled } from '@silverstein-properties/inspirelabs-ui';

export const LeftStyledInputField = styled(InputField)(() => ({
  '& fieldset': {
    borderRightColor: 'transparent',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const RightStyledInputField = styled(InputField)(() => ({
  '& fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));
