import { LabelField } from '@/components';
import { Experience, ProductTypes } from '@/types';
import { Stack, Typography } from '@silverstein-properties/inspirelabs-ui';
import { useCategoriesChoices, useSubCategoriesChoices } from '@/hooks';

export type BasicInfoSectionPropsType = {
  experience: Experience;
};

export const BasicInfoSection = ({ experience }: BasicInfoSectionPropsType) => {
  // If `purchasableType` = space, we need to fetch the space categories,
  // otherwise, fetch all other categories (exclude space categories)
  const isProductTypeSpace = experience.type === ProductTypes.SPACE;
  const { data: categories, isLoading } = useCategoriesChoices(
    isProductTypeSpace ? experience.type : undefined
  );

  const categoryIds = categories?.map(category => category.id);
  const { data: subcategories } = useSubCategoriesChoices(categoryIds);

  if (isLoading) {
    return null;
  }

  const categoriesArr =
    typeof experience.category?.type === 'string'
      ? [experience.category?.type]
      : experience.category?.type;

  const subCategoriesArr =
    typeof experience.category?.info === 'string'
      ? [experience.category?.info]
      : experience.category?.info;

  return (
    <Stack spacing={4}>
      <LabelField
        label="Categories that best describe your experience"
        value={
          <>
            {(categoriesArr ?? []).map(selectedCategory => {
              const category = categories?.find(
                category => category.value === selectedCategory
              );

              const subcategoriesList =
                (subCategoriesArr ?? [])
                  ?.map(selectedCategory => {
                    const subcategory = subcategories?.find(
                      subcategory =>
                        subcategory.value === selectedCategory &&
                        subcategory.categoryId === category?.id
                    );
                    return subcategory ? subcategory.label : null;
                  })
                  ?.filter(value => !!value)
                  .join(', ') || 'No subcategories';

              return (
                <Typography key={category?.id}>
                  {`${category?.label}: ${subcategoriesList}`}
                </Typography>
              );
            })}
          </>
        }
      />
    </Stack>
  );
};
