import { AmenitiesSectionEditInput, AddOnFormItem } from '@/classes';
import { PRICE_UNIT_CHOICES } from '@/constants';
import { AmenityPriceType } from '@/types';
import { Box, FormControlLabel, Checkbox } from '@mui/material';
import { InputField } from '@silverstein-properties/inspirelabs-ui';
import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
} from 'react-hook-form';
import {
  LeftStyledInputField,
  RightStyledInputField,
} from './AmenityAddOn.styles';

interface AddOnItemProps {
  addOn: AddOnFormItem;
  control: Control<AmenitiesSectionEditInput>;
  isCustom?: boolean;
  watchedValue?: AddOnFormItem;
  index: number;
  errors: FieldErrors<AmenitiesSectionEditInput>;
}

export const AddOnItem = ({
  addOn,
  control,
  isCustom = false,
  watchedValue,
  index,
  errors,
}: AddOnItemProps) => {
  const onChangeAddOn = (
    field: ControllerRenderProps<AmenitiesSectionEditInput, 'addOns'>,
    addOn: AddOnFormItem,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = field.value;
    if (!e.target.checked) {
      delete newValue[index];
    } else {
      newValue[index] = addOn;
    }
    field.onChange(newValue);
  };

  return (
    <Box mb={2}>
      {isCustom ? (
        <Controller
          control={control}
          name={`customAddOns.${index}.name`}
          render={({ field }) => (
            <InputField
              {...field}
              label="Name"
              error={!!errors.customAddOns?.[index]?.name}
            />
          )}
        />
      ) : (
        <FormControlLabel
          className="form-xl"
          label={addOn.name}
          control={
            <Controller
              control={control}
              name="addOns"
              render={({ field }) => (
                <Checkbox
                  data-testid="add-on-item"
                  checked={field.value.some(item => item.name === addOn.name)}
                  onChange={e => {
                    onChangeAddOn(field, addOn, e);
                  }}
                />
              )}
            />
          }
        />
      )}
      {watchedValue && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={4}
        >
          <Controller
            control={control}
            defaultValue={watchedValue.price || 0}
            name={`${isCustom ? 'customAddOns' : 'addOns'}.${index}.price`}
            render={({ field }) => (
              <LeftStyledInputField
                {...field}
                label="Price"
                type="number"
                isPriceInput
                error={
                  isCustom
                    ? !!errors.customAddOns?.[index]?.price
                    : !!errors.addOns?.[index]?.price
                }
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={
              watchedValue.unit ||
              (PRICE_UNIT_CHOICES[0].value as AmenityPriceType)
            }
            name={`${isCustom ? 'customAddOns' : 'addOns'}.${index}.unit`}
            render={({ field }) => (
              <RightStyledInputField
                {...field}
                label="Unit"
                select
                dropdownItems={PRICE_UNIT_CHOICES}
                aria-labelledby="add-on-unit-title"
                aria-describedby="add-on-unit-error"
                error={
                  isCustom
                    ? !!errors.customAddOns?.[index]?.unit
                    : !!errors.addOns?.[index]?.unit
                }
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};
