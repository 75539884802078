import { BookingMethod } from '@/types';
import {
  ApplicantsIcon,
  CateringIcon,
  LinkIcon,
  ScheduleIcon,
  SpacesIcon,
} from '@silverstein-properties/inspirelabs-ui';
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';

export interface SelectBookingTypeDialogType {
  bookingMethod: BookingMethod;
  title: string;
  description: string;
  dataTestId: string;
  icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
  >;
}

const BookingTypeDialogIconMap: Record<
  string,
  ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
  >
> = {
  ApplicantsIcon,
  CateringIcon,
  LinkIcon,
  ScheduleIcon,
  SpacesIcon,
};

type BookingTypeDialogOptionsProps = {
  isBookingRequestEnabled: boolean;
  isCateringEnabled: boolean;
  isAdvertEnabled: boolean;
  isSpaceEnabled: boolean;
};

export const BOOKING_TYPE_DIALOG_OPTIONS = ({
  isAdvertEnabled,
  isBookingRequestEnabled,
  isCateringEnabled,
  isSpaceEnabled,
}: BookingTypeDialogOptionsProps): SelectBookingTypeDialogType[] => [
  {
    bookingMethod: BookingMethod.INSTANT,
    title: 'Instant Booking',
    description:
      'I provide a fixed schedule where customers can book instantly',
    dataTestId: 'bookingTypeInstant',
    icon: BookingTypeDialogIconMap['ScheduleIcon'],
  },
  ...(isBookingRequestEnabled
    ? [
        {
          bookingMethod: BookingMethod.REQUEST,
          title: 'Booking Request',
          description:
            'Customers will contact me to set up final scope of service, date and time.',
          dataTestId: 'bookingTypeRequest',
          icon: BookingTypeDialogIconMap['ApplicantsIcon'],
        },
      ]
    : []),
  ...(isCateringEnabled
    ? [
        {
          bookingMethod: BookingMethod.DELIVERY,
          title: 'Catering',
          description: 'Customers can choose amongst menu items from Square',
          dataTestId: 'bookingTypeCatering',
          icon: BookingTypeDialogIconMap['CateringIcon'],
        },
      ]
    : []),
  ...(isAdvertEnabled
    ? [
        {
          bookingMethod: BookingMethod.ADVERT,
          title: 'Advert',
          description:
            'Customers can see my advertisement before being redirected to my link',
          dataTestId: 'bookingTypeAdvert',
          icon: BookingTypeDialogIconMap['LinkIcon'],
        },
      ]
    : []),
  ...(isSpaceEnabled
    ? [
        {
          bookingMethod: BookingMethod.SPACE,
          title: 'Spaces',
          description: 'You offer commercial spaces forasdaa',
          dataTestId: 'bookingTypeSpaces',
          icon: BookingTypeDialogIconMap['SpacesIcon'],
        },
      ]
    : []),
];
