import { ApiClient } from '@/api/apiClient';
import { Amenity, AmenityType } from '@/types';
import { useQuery } from '@tanstack/react-query';

export const useAmenities = () => {
  const apiClient = ApiClient();

  return useQuery<Amenity[] | undefined>(['amenities'], () =>
    apiClient.amenities.getAllAmenities()
  );
};

export const useAmenitiesByType = (type: AmenityType) => {
  const apiClient = ApiClient();

  return useQuery<Amenity[] | undefined>(['amenities', type], () =>
    apiClient.amenities.getAmenitiesByType(type)
  );
};
